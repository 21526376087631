$message__margin-top            : $spacer--medium !default;
$message__padding               : $spacer $spacer--medium !default;
$message__padding\@medium       : $spacer $spacer--extra-large !default;
$message__margin                : $spacer auto !default;
$message__font-size             : $font-size-base !default;
$message__transition            : $transition-base !default;
$message__background            : $bg-color-brand-2 !default;
$message__background--success   : $color-brand !default;
$message__background--error     : $bg-color-error !default;
$message__background--warning   : $color-brand-2 !default;
$message__color                 : $color-brand-accent !default;
$message__color--success        : $color-inverse !default;
$message__color--error          : $color-inverse !default;
$message__color--warning        : $color-brand-accent !default;
$message__border-width          : $spacer--extra-small !default;
$message__border-color          : $bg-color-base !default;
$message__border-style          : solid !default;
$message__border-color--success : $bg-color-base !default;
$message__border-color--error   : $bg-color-base !default;
$message__border-color--warning : $bg-color-base !default;
$message__border-radius         : 0 !default;
$message__icon-spacer           : $spacer--semi-medium !default;
$message__icon-width            : $spacer--semi-medium + $spacer--small !default;
$message__icon-order            : -1 !default;
$message__icon-margin           : 0 $spacer 0 0 !default;
$message__icon-fill             : $bg-color-base !default;
$message__icon-fill--success    : $bg-color-base !default;
$message__icon-fill--error      : $bg-color-base !default;
$message__icon-fill--warning    : $bg-color-base !default;
$message__icon-bg-image         : null;
$message__icon-bg-image--success: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill="#{$message__icon-fill--success}" d="M7 .333A6.67 6.67 0 0 0 .333 7 6.67 6.67 0 0 0 7 13.667 6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333zm-1.333 10L2.333 7l.94-.94 2.394 2.387 5.06-5.06.94.946-6 6z"/></svg>') !default;
$message__icon-bg-image--error  : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill="#{$message__icon-fill--error}" d="M.667 13h14.666L8 .333.667 13zm8-2H7.333V9.667h1.334V11zm0-2.667H7.333V5.667h1.334v2.666z"/></svg>') !default;
$message__icon-bg-image--warning: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill="#{$message__icon-fill--error}" d="M.667 13h14.666L8 .333.667 13zm8-2H7.333V9.667h1.334V11zm0-2.667H7.333V5.667h1.334v2.666z"/></svg>') !default;
