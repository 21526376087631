.product-view {
    &__separator-title {
        font-size: $font-size-super-extra-large;
        font-style: italic;
        font-weight: $font-weight-normal;
        color: $color-brand-accent;
        margin: $spacer--semi-large 0;
        text-align: center;
    }
}
