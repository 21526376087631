.review-form {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    &__smileys {
        display: flex;
        justify-content: space-between;

        &-smiley {
            background: $bg-color-secondary;
            border-radius: $spacer;
            padding: $spacer;
            text-align: center;
            width: 150px;
            max-width: 30%;
            cursor: pointer;

            &:focus {
                outline: 1px solid $color-brand;
                box-shadow: 0 0 8px 2px rgba(153, 154, 54, 0.6);
            }

            &--selected,
            &--selected:focus {
                outline: 3px solid $color-brand;
                background: mix($bg-color-secondary, $bg-color-brand);
            }
        }

        &-image {
            margin: 0 auto $spacer;
        }

        &-input {
            font-size: $font-size-small;
        }
    }

    @include mq($screen-s) {
        &__smileys {
            justify-content: space-around;

            &-smiley {
                padding: $spacer--medium;
            }

            &-input {
                font-size: $font-size-base;
            }
        }
    }
}
