#mc_embed_signup.marleen {
    div.mce_inline_error {
        font-size: $font-size-small;
        color: $color-error;
        padding: 3px;
        background: transparent;
        font-weight: normal;
        margin: 0;
    }
    input.mce_inline_error {
        border-color: $color-error;
    }

    #mce-responses {
        margin-bottom: 1em;
    }
    #mce-error-response {
        color: $color-error;

        a {
            color: $color-error;
            text-decoration: underline;
        }
    }
    #mce-success-response {
        background: $color-success;
        color: white;
        padding: 6px;
        font-size: 17px;

        &::before {
            display: inline-block;
            content: '✔';
            margin-right: 0.3em;
            font-size: 19px;
            margin-left: 0.2em;
        }
    }
}
