$qty-indicator__font-size\@small: 14px;
$qty-indicator__font-size: 17px;
$qty-indicator__orders-background: #bbbbbb;
$qty-indicator__cart-background: $color-brand-2;

.qty-indicator {
    background: $qty-indicator__cart-background;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 50%;
    font-size: $qty-indicator__font-size\@small;
    font-weight: bold;
    color: $color-inverse;
    text-decoration: none;
    display: block;
    text-align: center;
    font-family: 'times new roman', 'times', serif;
    cursor: default;
    transition: transform 0.3s ease;

    &--orders {
        background: $qty-indicator__orders-background;
    }

    &--out {
        transform: scale(0.4);
    }

    @include mq($screen-m) {
        font-size: $qty-indicator__font-size;
    }

    &--inline {
        width: #{$font-line-height}em;
        height: #{$font-line-height}em;
        line-height: #{$font-line-height}em;
        display: inline-block;
        font-size: inherit;
    }
}

