$dashboard-table__header-margin         : 0 0 $spacer--semi-medium !default;
$dashboard-table__header-padding        : 0 !default;

$dashboard-table__content-margin        : 0 0 $spacer--semi-medium !default;

$dashboard-table__title-margin          : 0 $spacer--medium 0 0 !default;
$dashboard-table__title-font-family     : $font-family-base !default;
$dashboard-table__title-font-size       : $font-size-extra-large !default;
$dashboard-table__title-font-weight     : $font-weight-normal !default;
$dashboard-table__color                 : $color-brand-accent !default;

$dashboard-table__link-font-size        : $font-size-medium !default;
$dashboard-table__link-font-weight      : $font-weight-medium !default;
$dashboard-table__text-decoration       : none !default;

.dashboard-table {
    overflow: hidden;

    &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin: $dashboard-table__header-margin;
        padding: $dashboard-table__header-padding;
    }

    &__content {
        margin: $dashboard-table__content-margin;
        overflow-x: auto;
    }

    &__title {
        margin: $dashboard-table__title-margin;
        font-family: $dashboard-table__title-font-family;
        font-size: $dashboard-table__title-font-size;
        font-weight: $dashboard-table__title-font-weight;
        color: $dashboard-table__color;
    }

    &__link {
    }

    tfoot {
        .mark {
            text-align: right;
            font-weight: $font-weight-bold;
        }
        .amount {
            text-align: right;
        }
    }
}
