.first-letter {
    &:first-letter {
        font-size: (1.7 * 2 * $font-size-base);
        color: $color-brand;
        line-height: (1.5 * 2 * $font-size-base);
        float: left;
        margin-right: 5px;
        display: block;
    }
}
