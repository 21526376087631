@import 'nav-variables';

.dashboard-nav {
    &__mobile {
        @extend %arrow;

        display: block;
        position: relative;
        border: $dashboard-nav__mobile-border;
        border-width: $dashboard-nav__mobile-border-width;
        padding: $dashboard-nav__mobile-padding;
        margin: $dashboard-nav__mobile-margin;
        font-weight: $dashboard-nav__mobile-font-weight;
        cursor: pointer;
        background: $bg-color-base;

        @include mq($screen-m) {
            font-size: $font-size-extra-large;
        }
        @include mq($screen-l) {
            display: none;
        }
    }

    &__content {
        display: none;
        padding: $dashboard-nav__content-padding\@small;

        &--visible {
            display: block;
        }

        @include mq($screen-l) {
            padding: 0;
            display: block;
        }
    }

    &__item {
        position: relative;
        padding: $dashboard-nav__item-padding;
        transition: $dashboard-nav__item-transition;
        border-bottom: 1px solid #ccc;
    }

    &__item > a,
    &__link {
        display: block;
        padding: $dashboard-nav__link-padding;
        text-decoration: $dashboard-nav__link-text-decoration;
        font-size: $dashboard-nav__link-font-size;
        color: $color-brand-accent;

        &:hover {
            text-decoration: $dashboard-nav__link-text-decoration-hover;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin: $dashboard-nav__actions-margin;

        @include mq($screen-l) {
            margin: $dashboard-nav__actions-margin\@large;
        }
    }

    &__section {
        display: none;

        @include mq($screen-l) {
            display: flex;
            flex-direction: column;
        }
    }

    &__section-title {
        margin: $dashboard-nav__section-title-margin;
        font-family: $dashboard-nav__section-title-font-family;
        font-size: $dashboard-nav__section-title-font-size;
        letter-spacing: $dashboard-nav__section-title-letter-spacing;
    }

    &__section-info {
        margin: $dashboard-nav__section-info-margin;
    }

    // This class is changing by Magento
    .current {
        position: relative;
        background: $dashboard-nav__link-bg-current;

        .dashboard-nav__link {
            font-weight: $dashboard-nav__link-font-weight-current;

            &:hover {
                color: $dashboard-nav__link-color-current-hover;
                text-decoration: $dashboard-nav__link-text-decoration-current-hover;
            }
        }
    }

    // This clases to disabled links module
    .nav.item {
        @extend .list__item;
        @extend .dashboard-nav__item;

        @include mq($screen-l) {
            font-size: $dashboard-nav__item-font-size\@large;
        }

        &:not(.current) > a {
            padding: $dashboard-nav__item-link-padding;
        }
    }

    .nav.item.current {
        @extend .current;
    }

    .nav.item.current {
        @extend .dashboard-nav__link;

        & > a {
            padding: 0;
        }
    }

    .nav.item > a {
        @extend .link;
        @extend .dashboard-nav__link;
    }
}
