@mixin mq-height($min-height: false, $max-height: false) {

    @if ($min-height and $max-height) {
        @media all and (min-height: $min-height) and (max-height: $max-height) {
            @content;
        }
    }
    @else if ($max-height and not $min-height) {
        @media all and (max-height: $max-height) {
            @content;
        }
    }
    @else {
        @media all and (min-height: $min-height) {
            @content;
        }
    }
}
