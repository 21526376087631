// Base
$body__background-color                         : #eeeeee;
$paragraph-font-size                            : $font-size-base;
$container__max-width                           : $max-content-width;
$container__margin                              : $spacer;
$container__margin\@medium                      : $spacer;
$container__margin\@large                       : $spacer--semi-large;
$layout-sidebar-width                           : 32%;

// Typography
$headings-text-transform                        : none;
$headings-text-transform--page                  : none;
$heading-font-size--second-level\@screen-m      : $font-size-extra-large;
$heading-font-size--second-level                : $font-size-large + calc(($font-size-extra-large - $font-size-large) / 2);

// Messages
$messages__background-color                     : transparent;
$message__padding                               : $spacer $spacer--medium $spacer $spacer--semi-large;

// Table
$table__font-size                               : $font-size-base;
$table__font-size\@medium                       : $font-size-base;
$table__td-padding                              : $spacer--extra-small $spacer;
$table__td-padding\@medium                      : $spacer--extra-small $spacer;
$table__th-padding                              : $spacer--extra-small $spacer;
$table__th-padding\@medium                      : $spacer--extra-small $spacer;
$table__th-background                           : $bg-color-brand;
$table__td-width-before                         : 35%;
$table__tfoot-td-padding                        : $spacer--extra-small $spacer;
$table__tfoot-td-padding\@medium                : $spacer--extra-small $spacer;

// Forms
$input__field-spacing                           : 36px;
$account-form__forgot-password-color            : $color-brand;
$account-form__forgot-password-font-weight      : $font-weight-base;
$label-color                                    : $color-primary;
$select__height                                 : $input__field-spacing;
$select__padding                                : 0 $spacer--semi-large 0 $spacer--small;
$input__field-padding                           : 0 $spacer--small;
$input__margin-bottom                           : $spacer--small;
$radio__label-color                             : lighten($color-primary, 10%);
$select__field-background                       : $white;
$input__field-border                            : 1px solid $border-color-secondary;

// Dashboard form
$dashboard-form__label-font-size                : $font-size-base;

// Buttons
$button__min-size                               : 34px;
$button__padding                                : 0 18px 0 14px;
$button__background                             : transparent;
$button__text-transform                         : none;
$button__transition                             : none;

// Logo
$logo__max-width                                : 170px;
$logo__max-width\@medium                        : 325px;
$logo__max-width\@large                         : 325px;
$logo__image-height                             : 44px;
$logo__image-height\@medium                     : 60px;
$logo__image-height\@large                      : 84px;
$logo__image-width\@medium                      : auto;
$header__logo-padding                           : $spacer 0;
$header__wrapper-padding                        : 0;

// Header
$header__background                             : $bg-color-base;
$side-menu__bq-visibility-max                   : $screen-xl;
$side-menu__modal-container-max-width           : 270px;

// Menu
$side-menu__bg-color                            : $bg-color-secondary;

// Links
$link__color                                    : $color-brand;
$link__color-hover                              : null;
// $link__display                                  : inline;

// Slider
$range__handler-bg-color                        : $color-brand-2;
$range__handler-border                          : 5px solid $bg-color-base;
$range__track-bg-color                          : $bg-color-base;
$range__track-height                            : 10px;
$range__track-bg-color--active                  : $color-brand-2;
$range__handler-offset-top                      : -9px;
$range__handler-size                            : 28px;

// Account pages dashboard
$dashboard-items__title-background              : none;
$dashboard__sidebar-padding\@large              : 0;
$dashboard__sidebar-padding\@extra-large        : 0;
$dashboard__sidebar-border                      : none;
$dashboard__content-padding\@large              : 0 0 0 20px;
$dashboard__content-padding\@extra-large        : 0 0 0 20px;
$actions-group__link-text-decoration            : none;
$actions-group__link-font-size                  : $font-size-base;
$actions-group__link-padding                    : 0;
$actions-group__button-width                    : auto;

$input__button-pass-top                         : 31px;

// Footer
$footer__background-color                       : #fff;

// Password strength
$password-strength__indicator-padding           : 0 $spacer;
$password-strength__indicator-border-radius     : 5px;
$password-strength__color                       : $color-primary;
$password-strength__indicator-color--very-strong : $white;

// Pager
$pager__item-size                               : $spacer--large + 5;
$pager__item-margin                             : 0;
$pager__item-first-child-margin                 : 0 $spacer--extra-small 0 0;
$pager__item-last-child-margin                  : 0 0 0 $spacer--extra-small;
$pager__link-size                               : $pager__item-size;

// Toolbar
$toolbar__pager-order--secondary                : 2;
$toolbar__pager-margin--secondary               : $spacer--small 0;
$toolbar__amount-order--secondary               : 1;
$toolbar__pager-order--secondary                : 4;
$toolbar__sorter-limiter-order--secondary       : 3;
$toolbar__padding--secondary                    : 0;

// This is equal to the default, but I need these variables elsewhere
$grid__gutter-width                             : $spacer--medium;
$grid__gutter-half-width                        : calc(#{$grid__gutter-width} * 0.5);
$grid__gutter-compensation                      : calc(#{$grid__gutter-half-width} * -1);
