.friend-account {

    &__coupon {
        text-align: center;
        margin-top: 30px;

        .label {
            display: block;
            font-weight: 700;
        }
    }

    &__coupon-code {
        font-size: 32px;
        line-height: 40px;
        padding: 10px;
        border: 2px solid $color-brand-accent;
        margin: .2em auto 0;
        text-align: center;
        clear: both;
        display: block;
        min-width: 8em;
        width: 100%;
        max-width: 430px;
    }

    &__share {
        margin-top: 30px !important;
        text-align: center;
    }

    &__social {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        a {
            margin: 0 10px;
        }

        img {
            width: 36px;
        }
    }

    &__conditions {
        margin-top: 1.5em;
        font-size: 85%;
    }
}
