$menu-dish__min-width                           : 270px;
$menu-dish__min-width\@small                    : 160px;
$menu-dish__gutter                              : $spacer--semi-medium;
$menu-dish__gutter\@small                       : $spacer;
$menu-dish__actions-height                      : $spacer--large + 2 * $spacer--extra-small; // Size + padding
$menu-dish__actions-height\@small               : $spacer--semi-medium + 2 * $spacer--extra-small; // Size + padding

.menu-dish {
    background: $bg-color-base;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    padding: $spacer--extra-small;

    // This class needs to be on the parent element inside .menu-day
    &__wrapper {
        //display: flex;
        display: grid;
        //flex-wrap: wrap;
        grid-template-columns: repeat(auto-fit, minmax($menu-dish__min-width\@small, 1fr));
        grid-gap: $menu-dish__gutter\@small;
        //margin-left: -1 * ($menu-dish__gutter\@small / 2);
        //margin-right: -1 * ($menu-dish__gutter\@small / 2);

        @include mq($screen-m) {
            grid-template-columns: repeat(auto-fit, minmax($menu-dish__min-width, 1fr));
            grid-gap: $menu-dish__gutter;
            //margin-left: -1 * ($menu-dish__gutter / 2);
            //margin-right: -1 * ($menu-dish__gutter / 2);
        }
    }

    &__image {
        display: block;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__tag {
        position: absolute;
        bottom: $spacer--extra-small;
        right: 0;
        color: $white;
        font-weight: bold;
        font-size: $font-size-small;
        font-style: italic;
        padding: 0 5px 0 9px;
        cursor: inherit;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: top left;

        @include mq($screen-m) {
            bottom: $spacer--medium;
            font-size: $font-size-base;
            padding: 2px 8px 2px 16px;
        }

        &.-new {
            background-image: url("data:image/svg+xml,%3Csvg reserveAspectRatio='none' version='1.1' viewBox='0 0 170 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7 0h163v30h-170c2-10 4-20 7-30z' fill='%23dd0000'/%3E%3C/svg%3E");
        }
        &.-no-waste {
            background-image: url("data:image/svg+xml,%3Csvg reserveAspectRatio='none' version='1.1' viewBox='0 0 170 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7 0h163v30h-170c2-10 4-20 7-30z' fill='%2301c519'/%3E%3C/svg%3E%0A");
        }
    }

    &__caption {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__label {
        padding: $spacer 0;
        text-align: center;
        color: $color-brand-accent;
        font-size: $font-size-small;

        &-type {
            display: block;
            font-style: italic;
        }

        &-name {
            display: block;
            color: $color-brand-accent;
            font-weight: $font-weight-bold;
        }

        @include mq($screen-m) {
            font-size: $font-size-base;
        }
    }

    &__actions {
        display: flex;
        // background: url('../images/skewed-line-whitesmoke.svg') no-repeat center center;
        // background-size: 14px 100%;
        padding: $spacer--extra-small;
        text-align: center;
        align-items: center;
        margin-top: auto;
        height: $menu-dish__actions-height\@small;

        &--soldout {
            background: none;
        }

        @include mq($screen-m) {
            height: $menu-dish__actions-height;
        }
    }

    &__action-dummy {
        font-style: italic;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
    }

    &__action {
        flex-basis: 50%;
        flex-grow: 1;
        display: block;
        position: relative;

        &--info {
            background: url('../images/icon-i-green.svg') no-repeat center center;
            background-size: contain;

            &:hover {
                background-image: url('../images/icon-i-green-hover.svg');
            }
        }

        &--price {
            color: $color-primary;
            font-size: $font-size-base;
            line-height: 1.2em;

            //span {
            //    display: inline-block;
            //    background: $bg-color-brand-lighter;
            //    padding: $spacer--extra-small $spacer--small;
            //}
        }

        &--cart {
            background: url('../images/plus-shoppingbag.svg') no-repeat center center;
            background-size: auto 26px;
            border: 0;
            cursor: pointer;
            height: 100%;

            &:hover {
                background-image: url('../images/plus-shoppingbag-hover.svg');
            }
        }

        &.-is-filled {
            background-image: url('../images/plus-shoppingbag-clean.svg');

            &:hover {
                background-image: url('../images/plus-shoppingbag-clean-hover.svg');
            }
        }

        .qty-indicator {
            position: absolute;
            left: calc(50% - 1.3em);
            top: -0.25em;
        }

        @include mq($screen-m) {
            &--price {
                font-size: $font-size-medium;

                //span {
                //    padding: $spacer--extra-small $spacer--medium;
                //}
            }
        }
    }
}
