.login-form {
    &--popover {
        .fieldset__legend {
            text-align: center;
        }

        .account-form__fieldset {
            margin-bottom: 0;
        }

        .login-form__forgot-password {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
