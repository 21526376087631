/** In addition to _error.scss: add a space after the required input label */
div {
    &.required {
        label {
            &:not(.checkbox__label):not(.control__label) {
                &:after {
                    margin-left: .25em;
                    display: inline-block;
                }
            }
        }
    }
}

.order-grid-canceled-indicator {
    color: $color-cancel;
    font-style: italic;
}
