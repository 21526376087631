$notice-popover__exclamation__size: 40px;

.notice-popover {
    &__exclamation {
        transition: none;
        position: fixed;
        left: 8px;
        bottom: 8px;
        // width: $notice-popover__exclamation__size;
        height: $notice-popover__exclamation__size;
        border-radius: calc($notice-popover__exclamation__size / 2);
        padding: 0 16px;
        background-color: #cc3333;
        border: 3px solid white;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
        line-height: $notice-popover__exclamation__size * 0.9;
        color: white;
        z-index: 1000;
        font-size: $notice-popover__exclamation__size * 0.4;
        font-weight: 900;
        text-align: center;
        cursor: pointer;

        @include mq($screen-m) {
            position: absolute;
            left: 16px;
            top: 16px;
        }
    }
}

.notice-popover-enter-active {
    transition: all 0.5s ease;
    transition-property: opacity, transform;
}
.notice-popover-leave-active {
    transition: opacity 0.2s ease;
}

.notice-popover-enter-from {
    transform: translateY(40px);
    opacity: 0;

    @include mq($screen-m) {
        transform: translateY(-40px);
    }
}

.notice-popover-leave-to {
    opacity: 0;
}
