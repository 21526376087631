.cart-payment {
    padding: $spacer--semi-medium $spacer--large;
    background: $bg-color-brand-lighter;

    &__title {
        @extend .cart-summary__title;
    }

    &__form {
        background: $bg-color-brand-lightest;
        padding: $spacer;

        &-actions {
            margin: $spacer--medium 0 $spacer;
            text-align: right;
        }
    }

    &__method {
        margin-bottom: $spacer--small;
    }

    &__actions {
        text-align: center;
        margin-top: $spacer--medium;
    }

    &__messages {
        list-style-type: none;
        margin: $spacer--medium 0 0 0;
        padding: 0;
    }

    &__messages-item {
        margin: 0 0 1em;
        padding: 0;
        font-style: italic;
        font-size: $font-size-small;
        text-align: center;
    }
}

.cart-payment--account {
    padding: 0;
    background: none;

    .cart-payment {
        &__title {
            display: none;
        }
    }
}
