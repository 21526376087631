@import "dashboard";

$dashboard__sidebar-padding\@large              : $grid__gutter-half-width;
$dashboard__sidebar-padding\@extra-large        : $grid__gutter-half-width;

#maincontent.dashboard {
    @include mq($screen-m) {
        margin-top: $spacer--medium;
    }
    @include mq($screen-l) {
        margin-top: $spacer--large;
    }
}

.dashboard {
    &__content {
        padding: 0 calc($spacer--medium / 2);

        > div:not(.actions-group):not(.toolbar) {
            background: $bg-color-base;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            // Place items full width on mobile
            margin: 0 $grid__gutter-compensation $spacer--semi-medium;
            padding: $spacer--medium;
        }
    }

    @include mq($screen-m) {
        &__content {
            > div:not(.actions-group):not(.toolbar) {
                padding: $spacer--large;
                margin: 0 0 $spacer--semi-medium;
            }
        }
    }

    p {
        margin: 0 0 10.5px;
    }

    &__subtitle {
        font-size: $font-size-large;
        color: $color-brand-accent;
    }
}

.account .header-title {
    display: none;

    @include mq($screen-l) {
        display: block;
    }
}
