$popover__content-padding         : $spacer--medium;
$popover__content-padding\@small  : $spacer--small $spacer--medium;
$popover__background              : $bg-color-brand;
$popover__padding                 : $spacer--small;

.popover__mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popover {
    min-height: 200px;
    width: 90%;
    background: $bg-color-base;
    padding: $popover__padding;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    max-height: calc(100vh - #{$spacer--medium});
    overflow: hidden;

    &__header {

    }

    &__content {
        flex-grow: 1;
        padding: $popover__content-padding\@small;
        background: $popover__background;
        max-height: 100%;
        overflow: auto;
    }

    &__footer {
        flex-grow: 0;
        margin-top: auto;
        padding: $spacer $spacer 0;
        text-align: center;

        a {
            color: $color-brand-accent;
            text-decoration: underline;
        }
    }

    @include mq($screen-m) {
        min-width: 400px;
        max-width: 500px;

        &__content {
            padding: $popover__content-padding;
        }

        &__footer {
            padding: get-side($popover__padding, bottom) $spacer--small 0;
        }
    }

    &--bare {
        max-width: 1100px;

        .popover {
            &__content {
                padding: 0;
                background: none;
            }
        }
    }

    &--bottom {
        align-self: flex-end;
        transition: transform 500ms ease-in-out 300ms;
        margin-bottom: $spacer;
    }
}

body.has-open-popover {
    overflow: hidden;
}

.popover-enter-from {
    opacity: 0;

    .popover--bottom {
        transform: translateY(100%);
    }
}

.popover-leave-active {
    opacity: 0;

    .popover--bottom {
        transform: translateY(100%);
    }
}

.popover-enter-from .popover,
.popover-leave-active .popover {
    //-webkit-transform: scale(1.1);
    //transform: scale(1.1);
    //transform: translateY(-50px);
}
