$divider__height: $spacer--large;

.postcode-form {
    &__divider {
        border-bottom: $border-inverse;
        margin-bottom: $spacer--large;
        min-height: $spacer--large;
        text-align: center;

        span {
            font-size: $font-size-large;
            color: $color-brand-accent;
            font-style: italic;
            display: inline-block;
            width: auto;
            position: relative;
            line-height: 30px;
            top: 15px;
            padding: 0 12px;
            background: #e3e5cb;
            margin: 0;
        }
    }
}
