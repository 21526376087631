$dashboard-items__padding                : 0 !default;

$dashboard-items__title-font-family      : $font-family-base !default;
$dashboard-items__title-font-size        : $font-size-extra-large !default;
$dashboard-items__title-font-weight      : $font-weight-normal !default;

$dashboard-items__subtitle-font-size     : $font-size-large !default;
$dashboard-items__subtitle-font-weight   : $font-weight-normal !default;
$dashboard-items__subtitle-color         : $color-brand-accent !default;

$dashboard-items__collapsible-transform  : rotate(180deg) !default;

.dashboard-items {
    &__item {
        // padding: $dashboard-items__padding;
    }

    &__title,
    &__subtitle {
        font-family: $dashboard-items__title-font-family;
    }

    &__title {
        font-size: $dashboard-items__title-font-size;
        font-weight: $dashboard-items__title-font-weight;
        color: $color-brand-accent;

        .dashboard-form & {
            padding: 0;
        }
    }

    &__collapsible {
        &--active {
            .icon {
                transform: $dashboard-items__collapsible-transform;
            }
        }
    }

    &__title-collapsible {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__subtitle {
        font-size: $dashboard-items__subtitle-font-size;
        font-weight: $dashboard-items__subtitle-font-weight;
        color: $dashboard-items__subtitle-color;
    }
}
