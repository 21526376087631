.badge {
    display: inline-flex;
    padding: 1px 5px;
    margin: 1px 8px 1px 0;
    border-radius: 3px;
    color: $color-primary;
    background: $bg-color-brand;
    font-family: $font-family-sans-serif;
    font-size: 90%;
    justify-content: center;
    font-style: normal;

    &--new {
        color: $color-inverse;
        background: $color-warning;
    }
}
