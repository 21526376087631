// Colors
$white                       : #fff !default;
$black                       : #000 !default;
$red                         : #e62325 !default;
$orange                      : #fab216 !default;
$yellow                      : #ffd500 !default;
$green                       : #13b200 !default;
$blue                        : #0075e4 !default;
$bright-sky-blue             : #00b8ff !default;
$teal                        : #0194ab !default;
$pink                        : #ff5b77 !default;
$purple                      : #613d7c !default;
$seafoam-blue                : #77ccb9 !default;

$color-brand-accent          : #57272b; // @accent-color
$color-brand                 : #999a36; // @marleen-color
$color-brand-2               : #e7b239; // @orange-color
$color-brand-light           : #e3e5cb; // @light-green-color

// Grayscale colors
$gray-darkest                : #1a1b1d !default;
$gray-darker                 : #4d4d4d !default;
$gray-dark                   : #969696 !default; // @faded-color
$gray                        : #d6d6d6 !default; //
$gray-light                  : #eeeeee !default; // @gray-bg
$gray-lighter                : #f3f3f3 !default;
$gray-lightest               : #f7f7f9 !default;

// Semantic color scheme
$color-primary               : $gray-darker !default; // @default-color
$color-highlight             : darken($red, 10%);
$color-inactive              : rgba(0, 0, 0, 0.4);
$color-secondary             : $gray-dark !default;
$color-success               : $color-brand !default;
$color-info                  : $blue !default;
$color-warning               : darken($orange, 15%) !default;
$color-error                 : $red !default;
$color-inverse               : $white !default;
$color-focus                 : $color-brand !default;
$color-cancel                : #aa0000;

// Background
$bg-color-base               : $white !default;
$bg-color-secondary          : $gray-lighter !default;
$bg-color-dark               : $gray-darkest !default;
$bg-color-brand              : $color-brand-light;
$bg-color-brand-lightest     : lighten($bg-color-brand, 13%); // #f5f6ed
$bg-color-brand-lighter      : lighten($bg-color-brand, 10%); // #eceddc;
$bg-color-brand-2            : lighten($color-brand-2, 10%);
$bg-color-brand-2-lighter    : lighten($color-brand-2, 30%);
$bg-color-error              : #C04040;

// Border
$border-color-base           : $gray !default;
$border-color-light          : $gray-light !default;
$border-width-base           : 1px !default;
$border-style-base           : solid !default;
$border-color-secondary      : #cacaca !default;
$border-width-secondary      : 1px !default;
$border-style-secondary      : solid !default;
$border-color-inverse        : $color-inverse;

$border-base                 : $border-width-base $border-style-base $border-color-base !default;
$border-secondary            : $border-width-secondary $border-style-secondary $border-color-secondary !default;
$border-inverse              : $border-width-base $border-style-base $border-color-inverse !default;

// Outline
$outline-base                : none !default;

// Focus
$border-focus                : 1px solid $color-focus !default;
$border-focus-dark           : 1px solid $color-primary !default;

// Focus inline
$color-focus-inline          : $color-primary !default;
$color-focus-inline-dark     : $color-primary !default;
$bg-focus-inline             : $color-focus !default;
$bg-focus-inline-dark        : $color-primary !default;
$outline-focus-inline        : 0 !default;
$text-decoration-focus-inline: underline !default;
$focus-outline-offset        : -1px !default;
$focus-input-border          : $border-focus-dark;

// Spacers
//$spacer--extra-small         : 4px !default;
//$spacer                      : 8px !default;
//$spacer--small               : 12px !default;
//$spacer--medium              : 16px !default;
//$spacer--semi-medium         : 24px !default;
//$spacer--large               : 32px !default;
//$spacer--semi-large          : 40px !default;
//$spacer--extra-large         : 48px !default;
//$spacer--super-extra-large   : 64px !default;
$spacer--extra-small         : 5px !default;
$spacer                      : 10px !default;
$spacer--small               : 15px !default;
$spacer--medium              : 20px !default;
$spacer--semi-medium         : 25px !default;
$spacer--large               : 30px !default;
$spacer--semi-large          : 40px !default;
$spacer--extra-large         : 50px !default;
$spacer--super-extra-large   : 65px !default;

// Typography
$font-family-sans-serif      : 'Arial', sans-serif !default;
$font-family-serif           : 'Georgia', serif !default;
$font-family-monospace       : Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

$font-family-base            : $font-family-serif !default;
$font-family-secondary       : $font-family-serif !default;

$font-color-base             : $color-primary !default;
$font-weight-normal          : 400 !default;
$font-weight-medium          : 500 !default;
$font-weight-bold            : 700 !default;
$font-weight-base            : $font-weight-normal !default;
$font-line-height            : 1.6 !default;

$font-size-super-duper-extra-large : 36px !default;
$font-size-super-extra-large : 30px !default;
$font-size-extra-large       : 24px !default;
$font-size-large             : 20px !default;
$font-size-medium            : 18px !default;
$font-size-base              : 16px !default;
$font-size-small             : 14px !default;
$font-size-extra-small       : 11px !default;

// Z-index
$z-index-lowest              : -1 !default;
$z-index-initial             : auto !default;
$z-index-low                 : 1 !default;
$z-index-normal              : 10 !default;
$z-index-high                : 50 !default;
$z-index-highest             : 99 !default; // reserved for modals - check _modal-variables.scss for more

// Layout
//$max-content-width           : 1328px !default;
$max-content-width           : 1146px;

// Layout - breakpoints
$screen-xs                   : 320px !default;
$screen-s                    : 480px !default;
$screen-sm                   : 560px !default; // Extra size for MKT
$screen-m                    : 768px !default;
$screen-l                    : 960px !default;
$screen-xl                   : 1180px !default;
$screen-xxl                  : 1600px !default;
$screen-hd                   : 1920px;

$screen-height-l             : 740px;

// shadow
$shadow                      : 0 2px 6px 0 rgba(0, 0, 0, 0.1) !default;
$darken-layout-hover         : rgba($black, 0.2) !default;

// animations
$animation-position-top      : -300px !default;
$animation-position-right    : -300px !default;
$animation-position-left     : -300px !default;

// Components
$border-radius               : 0 !default;
$form-elements-radius        : 0 !default;
$form-elements-radius--small : 0 !default;
$form-elements-border-color  : $border-color-secondary !default;
$form-element-border-width   : 1px !default;
$form-element-border-style   : solid !default;
$form-elements-border        : $form-element-border-width $form-element-border-style $form-elements-border-color !default;
$form-elements-border-focus  : 1px solid $color-brand !default;
$form-input-box-shadow-focus : inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(153, 154, 54, 0.6) !default;
$transition-base             : all 0.4s ease-in-out !default;
$transition-fade             : opacity 0.35s linear !default;

// Paths
$path-images                 : '../images/' !default;

// Blog
$toolbar__border-width       : 0 !default;
$toolbar__amount-color       : initial !default;

// Icon
$icon-padding: 0 !default;
$icon-size: 24px !default;

@import 'variables_overrides';
