@import "toolbar";

.toolbar {
    @include mq($screen-m) {
        flex-wrap: nowrap;

        &--secondary {
            .toolbar__pager {
                order: 2;
            }
        }
    }
}
