.cart-message {
    margin-top: $spacer--medium;
    padding: $spacer--small;
    background: $bg-color-brand-2-lighter;
    border: 2px solid $white;
    border-radius: $spacer--small;

    &__inline-button {
        padding: 1px 4px 1px 8px;
        background: mix($bg-color-brand-2, $bg-color-brand-2-lighter, 30%);
        border-radius: 6px;
        display: inline-flex;
        flex-direction: row;
        color: inherit;
        text-decoration: none;
        align-items: center;
        font-size: $font-size-base;

        &:hover {
            background: mix($bg-color-brand-2, $bg-color-brand-2-lighter, 60%);
        }
    }

    &__choose {
        margin-top: $spacer--medium;
        display: flex;
        flex-direction: column;
        gap: $spacer--small;

        @include mq($screen-s) {
            flex-direction: row;
        }
    }

    &__choice {
        background: mix($bg-color-brand-2, $bg-color-brand-2-lighter, 50%);
        padding: 4px 12px;
        margin: 0 $spacer--small 0 0;
        border-radius: 8px;
        border: 2px solid transparent;
        user-select: none;
        transition: background-color 300ms ease;
        cursor: pointer;

        &:hover {
            background: mix($bg-color-brand-2, $bg-color-brand-2-lighter, 80%);
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        }

        &--active {
            border: 2px solid white;
            background: $bg-color-brand-2;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        }
    }

    &__dismiss {
        width: 25px;
        min-height: 25px;
        background: url('#{$path-images}icon-delete-light.svg') no-repeat center right;
        background-size: contain;
        float: right;
        cursor: pointer;

        &:hover {
            background-image: url('#{$path-images}icon-delete.svg');
        }
    }
}
