@import "range";

.noUi-connect {
    box-shadow: none;
}

.noUi-marker-horizontal.noUi-marker {
    height: 10px;
    background: $bg-color-base;
    width: 1px;
}

.noUi-pips-horizontal {
    padding: 0;
    height: 10px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

.noUi-horizontal {
    .noUi-base {
    }

    .noUi-handle {
        right: calc(-1 * $range__handler-size / 2);

        &.noUi-active {
            $active-zoom-factor: 1.2;
            $size: $range__handler-size * $active-zoom-factor;
            width: $size;
            height: $size;
            // I assume here that dir != ltr (see some lines below here, in the 'html:not(...)')
            right: -1 * calc($size / 2);
            top: calc(($range__track-height - $size) / 2);

            // see 3 definitions below
            html:not([dir="rtl"]) & {
                right: -1 * calc($size / 2);
                left: auto;
            }
        }
    }
}

.noUI-wrapper-bounding-rect {
    padding: (calc(($range__handler-size - $range__track-height) / 2) + 1);
}
