.sidebar-highlight {
    @include content-block();

    color: $color-brand-accent;
    font-size: $font-size-medium;
    text-align: center;
    background: $bg-color-brand;
    border: 8px solid $white;

    h3 {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        font-style: italic;
        color: $color-brand-accent;
        text-align: center;
    }

    a {
        color: darken($link__color, 7%);
    }

    @include mq($screen-m) {
        padding: $spacer--large $spacer--medium;
    }
}
