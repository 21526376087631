.cms-block {
    p {
        margin: 0.4em 0 1em 0;
    }

    h2 {
        margin-top: 0.8em;
    }

    > h2 {
        margin-top: 0;
    }

    h2, h3, h4 {
        color: $color-brand-accent;
    }
}
