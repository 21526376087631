@import 'account-form-variables';

.account-form {
    .foot-note {
        width: 50%;
        margin: 20px auto 0;
        text-align: center;
    }

    &__info {
        max-width: $account-form__info-width;
        margin: $account-form__info-margin;
        font-size: $account-form__info-font-size;
        text-align: center;
    }

    &__form {
        margin: 0 auto;

        &.content-form {
            width: 100%;
        }
    }

    &__fieldset {
        width: 100%;
        margin: 0;
        border: none;
    }

    &__legend {
        margin: $account-form__legend-margin;
        padding: $account-form__legend-padding;
        width: $account-form__legend-width;
        font-family: $account-form__legend-font-family;
        font-size: $account-form__legend-font-size;
        font-weight: $account-form__legend-font-weight;
        text-transform: $account-form__legend-text-transform;
        text-align: center;

        &--hidden {
            @include visually-hidden();
        }
    }

    &__actions {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        width: $account-form__actions-width;
        margin: $spacer--medium auto 0;
    }

    &__button {
    }

    &__forgot-password,
    &__link {
        color: $account-form__forgot-password-color;
        font-weight: $account-form__forgot-password-font-weight;
        text-decoration: $account-form__forgot-password-text-decoration;

        &.focus-visible,
        &:hover {
            text-decoration: $account-form__forgot-password-text-decoration-hover;
        }
    }

    &__validation-tips {
        margin: $account-form__validation-tips-margin;
        font-size: $account-form__validation-tips-font-size;
    }

    @include mq($screen-m) {
        margin-top: $spacer--super-extra-large;

        &__form {
            &.content-form {
                width: 60%;
            }
        }
    }
}

.header-login__form,
.popover {
    .account-form {
        margin-top: 0;

        &__actions {
            justify-content: center;
        }
    }
}
