.address-warning {
    .table {
        border: none;

        td {
            display: table-cell;
            border: none;

            &:before {
                content: none;
            }
        }
    }
    border: 5px solid $color-brand-2;

    .actions {
        text-align: right;
    }

    .input-select {
        appearance: none;
        background: #fff url('../images/arrow-down-green.svg') no-repeat right 10px center;
        width: 100%;
        border: 1px solid $border-color-secondary;
        line-height: 30px;
        border-radius: 0;
        padding: 2px 50px 2px 10px;
    }

    @include mq(false, $screen-m) {

        .table {
            background: $color-brand-light;
        }

        th {
            display: inline-block;
            background: rgba(153, 154, 54, 0.3);
            font-size: $font-size-small;

            &.col-address {
                display: none;
            }
            &.col-order {
                width: 21%;
            }
            &.col-date {
                width: 39%;
            }
            &.col-time {
                width: 37%;
            }
        }

        tr {
            padding: 5px 0;
        }

        td {
            background: none;

            &.col-address {
                display: block;
            }
        }
    }
}
