.product-add {
    background: $bg-color-brand;
    padding: $spacer--medium;
    margin-top: $spacer--small;

    &__form {
    }

    &__qty {
        flex: 1 1;
    }

    &__actions {
        margin: $spacer--small 0 0;
        text-align: center;
    }

    &__button {
        position: relative;

        .qty-indicator {
            position: absolute;
            right: -.5em;
            top: -.5em;
        }
    }

    &__sold-out {
        text-align: center;
        font-style: italic;
    }

    &__free {
        text-align: center;
        border-bottom: 1px solid $bg-color-base;
        padding-bottom: $spacer--small;
        margin-bottom: $spacer--small;
    }

    &__promo {
        $arrow-size: $spacer * 0.8;
        $background-color: lighten($color-brand-2, 23%);
        position: relative;
        background: $background-color;
        padding: calc($spacer--extra-small / 2) $spacer--small;
        margin: $spacer auto 0;
        width: max-content;
        border-radius: 8px;
        z-index: 10;
        color: $color-primary;
        transition: none;
        font-size: 95%;
        display: block;

        &::before {
            content: '';
            background: $background-color;
            position: absolute;
            z-index: -5;
            top: calc(-1 * $arrow-size / 2);
            left: $spacer--medium;
            transform: rotate(45deg);
            width: $arrow-size * 2;
            height: $arrow-size * 2;
        }

        &[href]:hover {
            background: lighten($color-brand-2, 12%);
            color: $color-primary;
            text-decoration: none;

            span {
                text-decoration: underline;
            }

            &::before {
                background: lighten($color-brand-2, 12%);
            }
        }

        &-icon {
            color: $red;
            width: 1em;
            height: 1em;
            display: inline-block;
            vertical-align: -.125em;
            fill: currentColor;
        }

        @include mq($screen-s) {
            margin-left: $spacer--extra-large;
            margin-right: 0;
        }
    }

    @include mq($screen-s) {
        &__form {
            display: flex;
            width: 100%;
        }

        &__actions {
            margin-top: 0;
        }

        &__qty {
            flex: 1 1;
        }

        &__button {
            flex: 0 0;
            margin: 0 0 0 $spacer--small;
        }
    }
}
