.post-list {

    &__item {
        margin-bottom: 30px;
        @include content-block-small();
    }

    &__title {
        margin-bottom: 0;

        a {
            color: $color-brand-accent;
        }
    }

    &__info {
        color: $gray-dark;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        margin-bottom: $spacer--small;

        a {
            text-decoration: underline;
        }

        span:not(:last-child) {
            &:after {
                content: ' • ';
            }
        }
    }

    &__excerpt {
        margin-bottom: 10px;
    }

    &__read-more {
        text-decoration: underline;
    }
}
