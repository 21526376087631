.blog-post {
    @extend .content-block-small;
    margin-bottom: 100px;

    &__title {
        color: $color-brand-accent;
        font-size: $font-size-super-extra-large;
        margin-bottom: $spacer--extra-small;
    }

    &__info {
        color: $gray-dark;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        margin-bottom: $spacer--small;

        a {
            text-decoration: underline;
            color: $gray-dark;
        }

        span:not(:last-child) {
            &:after {
                content: ' • ';
            }
        }
    }

    &__content {
        p {
            margin: 0 0 10px 0;
        }
        h2 {
            font-size: $font-size-extra-large;
            color: $color-brand-accent;
            font-weight: $font-weight-normal;
            margin-bottom: $spacer--extra-small;

            strong {
                font-weight: $font-weight-normal;
            }
        }
    }

    &__details {
        padding-top: $spacer--medium;
        margin-top: $spacer--medium;
        border-top: 1px solid $border-color-base;
        color: $color-secondary;

        p {
            margin: 0 0 $spacer--extra-small;
        }
    }
}
