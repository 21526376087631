.cart-item {
    background: $bg-color-brand-lightest;
    padding: $spacer;
    margin-bottom: 2px;

    &__image {
        float: left;
        width: 100px;
        margin-right: $spacer;

        img {
            border: 4px solid $white;
        }
    }

    &__name {
        font-size: $font-size-base;
        line-height: 1.2em;
        // To prevent the whole item from wrapping to a new line. I don't know exactly how this works, but it does!
        display: flex;

        a {
            color: $color-brand-accent;
        }
    }

    &__summary {
        margin-top: $spacer--small;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        &-qty {
            flex: 0 125px;
        }

        &-subtotal {
            flex: 0 80px;
            font-size: $font-size-large;
            text-align: right;
        }

        &-remove {
            flex: 0 25px;
            margin-left: $spacer;

            &-link {
                width: 25px;
                min-height: 25px;
                background: url('#{$path-images}icon-delete-light.svg') no-repeat center right;
                background-size: contain;

                &:hover {
                    background-image: url('#{$path-images}icon-delete.svg');
                }
            }
        }
    }

    @include mq($screen-m) {
        padding: $spacer--small;

        &__image {
            width: 120px;

            img {
                $width: 120px;
                width: $width;
                height: calc($width / 1.5);
                object-fit: cover;
            }
        }

        &__name {
            font-size: $font-size-large;
        }

        &__summary {
            &-subtotal {
                flex: 0 100px;
            }

            &-remove {
                margin-left: $spacer--small;
            }
        }
    }
}
