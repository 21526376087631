@import 'footer-variables';

.footer {
    background-color: $footer__background-color;
    margin-top: $spacer--semi-large;

    &--checkout {
        .footer__bottom-bar-handler {
            padding: $footer__bottom-bar-handler-padding--checkout;

            @include mq($screen-m) {
                padding: 0;
            }
        }
    }

    &--email {
        background-color: $footer__background-color--email;
    }

    &__handler {
        position: relative;
        display: flex;
        flex-direction: column;

        @include mq($screen-l) {
            flex-flow: row wrap;
        }

        > * {
            padding-top: 0;
            padding-bottom: $spacer--large;

            @include mq($screen-l) {
                padding-top: $spacer--semi-large;
                padding-bottom: $spacer--large;
            }
        }
    }

    &__newsletter {
        @include mq($screen-l) {
            width: $footer__newsletter-width\@large;
            padding: $footer__newsletter-padding\@large;
            margin: $footer__newsletter-margin\@large;
        }
    }

    &__links {
        width: $footer__links-width;
        padding: $footer__links-padding;

        @include mq($screen-l) {
            padding: $footer__links-padding\@large;
        }

        .list__item  {
            @include mq($screen-m) {
                min-width: $footer__links-list-item-min-width\@medium;
            }
        }
    }

    &__links-list {
        padding: $footer__links-list-padding;
    }

    &__link {
        display: block;
        margin: $footer__link-margin;
        font-size: $footer__link-font-size;
        font-weight: $footer__link-font-weight;
        color: $footer__link-color;
        text-decoration: none;

        @include mq($screen-m) {
            margin: $footer__link-margin\@medium;
        }

        &:hover {
            color: $footer__link-color-hover;
        }
    }

    &__social-handler {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: $footer__social-handler-margin;
        order: 1;

        @include mq($screen-m) {
            align-items: center;
            flex-direction: row;
        }

        @include mq($screen-l) {
            flex-direction: column;
            flex: 1;
            order: 0;
            margin: $footer__social-handler-margin\@large;
            padding: $footer__social-handler-padding\@large;
            border-bottom: $footer__newsletter-border;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 1px;
                height: 90%;
                background: $gray;
            }
        }
    }

    &__bottom-bar {
        color: $footer__bottom-bar-color;
        background-color: $footer__bottom-bar-background;
    }

    &__bottom-bar-handler {
        display: flex;
        align-items: $footer__bottom-align;
        flex-direction: column;
        min-height: $footer__bottom-min-height;

        @include mq($screen-m) {
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
        }

        @include mq($screen-l) {
            padding: $footer__bottom-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $footer__bottom-padding\@xl;
        }
    }

    &__logo {
        margin: 0 auto;
        transform: translateY(-50%);
        align-self: flex-start;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 80px;

        img {
            height: auto;
        }

        @include mq($screen-l) {
            max-width: none;
        }
    }

    .bullets {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            line-height: 30px;
        }

        a {
            font-size: 14px;
            color: $color-brand-accent;

            @include mq($screen-l) {
                font-size: 17px;
            }

            &:before {
                content: '›';
                color: $color-brand;
                margin-right: 0.3em;
            }
        }
    }

    .col-social {
        display: none;

        @include mq($screen-l) {
            display: block;

            strong {
                font-size: 17px;
                color: $color-brand-accent;
            }
        }
    }

    .col-links {
        padding: 0;

        @include mq($screen-l) {
            padding: initial;
        }
    }

    .col-contact {
        text-align: center;
        margin-top: 20px;

        @include mq($screen-l) {
            text-align: left;
            margin-top: 0;
            padding-left: 60px;
            color: $color-brand-accent;
        }

        &__phone, &__email {
            margin: 0 0 10.5px;
            font-size: 14px;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            @include mq($screen-l) {
                font-size: 17px;
            }
        }

        &__phone {

            @include mq($screen-l) {
                a {
                    color: $color-brand-accent;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    .col-newsletter {
        display: none;

        @include mq($screen-l) {
            position: relative;
            display: block;
            margin-top: 10px;
            padding: 0 0 0 25px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -19px;
                left: 9px;
                width: 39px;
                height: 70px;
                background: url(../images/footer-newsletter.svg) no-repeat 0 0;
                background-size: contain;
            }
        }

        &__form {
            padding: 12px 0 12px 35px;
            background: url(../images/footer-newsletter-back.svg) no-repeat 0 0;
            background-size: contain;

            label {
                font-style: italic;
                font-size: 17px;
                font-weight: $font-weight-bold;
                color: $color-brand-accent;
            }

            .input-text {
                float: left;
                padding: 6px 12px;
                border-radius: 0;
                border: 1px solid $border-color-secondary;
                width: calc(100% - 40px);
                background: $white;
                cursor: text;
                text-align: left;

                &:disabled {
                    color: $color-secondary;
                }

                @include mq($screen-xl) {
                    width: calc(100% - 86px);
                }
            }
        }
    }
}
