.page-special-menu {
    background: $bg-color-brand;

    &--valentine {
        background: mix(lighten(#960419, 60%), #eeeeee);
    }

    &--vdz {
        background: mix(#f7ec67, #eeeeee);
    }

    &--pasen {
        background: mix(#F3E19D, #eeeeee, 60%);
    }

    &--pinksteren {
        background: mix(#f8d3d4, #eeeeee, 60%);
    }

    // Don't use any auto scrolling. This has been copied from M1 to be sure; at this moment there's no scrolling in M2
    &.catalog-product-view {
        .product-detail__sheet-content,
        .product-detail__sheet {
            height: auto;
        }
    }
}

.product-header {
    text-align: center;
    padding: $spacer--medium;
    background: rgba(255, 255, 255, 0.66);
    margin: $spacer;
    position: relative;
    overflow: hidden;
    border: 5px solid white;

    h2 {
        margin: 0 0 1em;
        font-style: normal;
        font-size: $font-size-large;
        color: $color-brand-accent;
    }

    p {
        margin-bottom: 1em;
        font-size: $font-size-base;
        line-height: 1.5em;

        &:first-of-type {
            color: $color-brand-accent;
            font-size: $font-size-medium;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    @include mq($screen-m) {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
        margin: $spacer--large 0;

        h2 {
            font-size: $font-size-extra-large;
        }

        p {
            font-size: $font-size-medium;

            &:first-of-type {
                font-size: $font-size-large;
            }
        }
    }
}

/*****************************************************************************
    DECEMBER MENU
.menu-dish {
    &.-layout-custom-december {
        $base-color: #b89a5d;
        $base-border-color: mix($base-color, #ffffff, 90%);
        // option 2 (red):
        // $base-border-color: mix($base-color, #999, 90%);

        position: relative;
        background: linear-gradient(135deg, $base-border-color, mix($base-border-color, #ffffff, 40%), $base-border-color, mix($base-border-color, #ffffff, 40%), $base-border-color);

        .menu-dish__caption {
            background: linear-gradient(135deg, #fff, mix($base-color, #ffffff, 5%), #fff, mix($base-color, #ffffff, 5%), #fff);
        }

        &::after {
            position: absolute;
            content: '';
            pointer-events: none;

            background: url("#{$path-images}product-layout/december-bow.svg") no-repeat top left;
            top: -5%;
            left: -6%;
            width: 40%;
            height: 40%;

            @include mq($screen-m) {
                background: url("#{$path-images}product-layout/december-mistletoe.svg") no-repeat center center;
                background-size: contain;
                width: 30%;
                height: 30%;
            }
        }
    }
}
.menu-day {
    &__december-banner {
        $base-color: #b89a5d;

        //$gradient-1: $base-border-color;
        //$gradient-2: mix($base-border-color, #ffffff, 60%);
        //background: linear-gradient(135deg, $gradient-1, $gradient-2, $gradient-1, $gradient-2, $gradient-1);
        //padding: $spacer--extra-small;
        //margin-top: $spacer;
        //
        //&-inner {
        //    $gradient-1: #e8e8e8;
        //    $gradient-2: mix(#999, $gradient-1, 10%);
        //    background: linear-gradient(135deg, $gradient-1, $gradient-2, $gradient-1, $gradient-2, $gradient-1);
        //    padding: $spacer;
        //    color: $base-color;
        //    font-style: italic;
        //    display: block;
        //    text-align: center;
        //}

        $gradient-1: mix($base-color, #ffffff, 50%);
        $gradient-2: mix($base-color, #ffffff, 70%);
        background: linear-gradient(135deg, $gradient-1, $gradient-2, $gradient-1, $gradient-2, $gradient-1);
        padding: $spacer--extra-small;
        margin-top: $spacer--medium;

        &-inner {
            $gradient-1: darken($base-color, 5%);
            $gradient-2: darken($base-color, 15%);
            background: linear-gradient(135deg, $gradient-1, $gradient-2, $gradient-1, $gradient-2, $gradient-1);
            padding: $spacer;
            color: $white;
            font-style: normal;
            font-weight: $font-weight-bold;
            display: block;
            text-align: center;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

            &:hover {
                $gradient-1: darken($gradient-1, 2%);
                $gradient-2: darken($gradient-2, 2%);;
                background: linear-gradient(135deg, $gradient-1, $gradient-2, $gradient-1, $gradient-2, $gradient-1);
            }

            @include mq($screen-m) {
                font-size: 110%;
            }
        }
    }
}

.december-menu-top {
    $base-color: #b89a5d;
    $gradient-1: mix($base-color, #ffffff, 30%);
    $gradient-2: mix($base-color, #ffffff, 50%);
    padding: $spacer--medium;
    background: linear-gradient(135deg, $gradient-1, $gradient-2, $gradient-1, $gradient-2, $gradient-1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    border: 5px solid $white;
    color: $color-brand-accent;
    display: block;
    text-align: center;
    //text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

    p {
        margin: 0 auto 1em;
        max-width: 55em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include mq($screen-m) {
        margin-top: $spacer--medium;
        font-size: 110%;
    }
}
**/

/*****************************************************************************
    THANKSGIVING
**/
.menu-day.-date-2023-11-23 .menu-dish.-meal-type-exclusive {
    border: 5px solid #571f20;
    background: #f7f5f2;
    position: relative;
}
.menu-day.-date-2023-11-23 .menu-dish.-meal-type-exclusive .menu-dish__image {
    padding: 0;
}
@media (min-width: 601px) {
    .menu-day.-date-2023-11-23 .menu-dish.-meal-type-exclusive::before {
        background: url('../images/banners/thanksgiving-pumpkins.png') no-repeat bottom right;
        content: '';
        position: absolute;
        top: -56px;
        height: 55px;
        width: 48px;
        background-size: contain;
        right: 10px;
    }
}
@media (min-width: 1180px) {
    .menu-day.-date-2023-11-23 .menu-dish.-meal-type-exclusive::before {
        width: 63px;
    }
}
