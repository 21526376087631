$dropdown-chooser__handle-size: 30px;
$dropdown-chooser__gutter: 10px;
$dropdown-chooser__input-height: $input__field-spacing;
$select__field-background: null !default;
$input__field-border: null !default;

.dropdown-chooser {
    display: flex;

    &--disabled {
        opacity: 0.5;

        .dropdown-chooser__handle {
            // visibility: hidden;
        }
    }

    &__select {
        flex-grow: 1;
        background: $select__field-background;
        border: $input__field-border;
        height: $dropdown-chooser__input-height;
        margin: 0 $dropdown-chooser__gutter;
        color: $color-brand-accent;
        line-height: $dropdown-chooser__input-height;
        text-align: center;

        select[disabled] {
            opacity: 1;
        }

        &-element,
        &-element:active,
        &-element:focus {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 0;
            background: transparent;
            line-height: inherit;
            padding: 0 $spacer;
            outline: 0;
            color: inherit;
            width: 100%;

            option {
                text-align: left;
            }
        }
    }

    &__handle {
        flex-basis: $dropdown-chooser__handle-size;
        flex-grow: 0;
        flex-shrink: 0;
        width: $dropdown-chooser__handle-size;
        height: $dropdown-chooser__handle-size;
        margin-top: calc(($dropdown-chooser__input-height - $dropdown-chooser__handle-size) / 2);
        background: url('#{$path-images}circle-minus.svg') no-repeat center center;
        background-size: contain;
        display: block;
        cursor: pointer;

        &--minus {
            background-image: url('#{$path-images}circle-minus.svg');
        }
        &--plus {
            background-image: url('#{$path-images}circle-plus.svg');
        }
        &--prev {
            background-image: url('#{$path-images}circle-prev.svg');
        }
        &--next {
            background-image: url('#{$path-images}circle-next.svg');
        }
        &--trash {
            background-image: url('#{$path-images}circle-trash.svg');
        }
        &--disabled,
        &--disabled:hover {
            cursor: default;
            opacity: 0.5;
        }
        &--hidden {
            visibility: hidden;
        }
    }
}
