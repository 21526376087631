$product-detail__main-width: 500px; // box-sizing: content-box
$product-detail__padding-x: $spacer--large;

.catalog-product-view {
    @include full-width-mobile();
}

.product-detail {
    margin: 0 0 $spacer--large;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    background: $bg-color-base;
    padding-bottom: $spacer;

    &__product {
        display: block;
        background: $bg-color-base;
        padding: $spacer;
    }

    &__media {
        margin-bottom: $spacer;
        position: relative;

        &-visual {
            position: relative;
        }

        &-image {
            max-width: 100%;
            width: 100%;
            max-height: 40vh;
            object-fit: cover;
        }

        &-label {
            width: 50px;
            height: 50px;
            background: transparent no-repeat left top;
            background-size: 50px auto;
            position: absolute;
            left: 0;
            top: $spacer--small;

            &--meat {
                background-image: url('#{$path-images}product/label-meat.svg');
            }
            &--fish {
                background-image: url('#{$path-images}product/label-fish.svg');
            }
            &--kids {
                background-image: url('#{$path-images}product/label-kids.svg');
            }
            &--vegetarian {
                background-image: url('#{$path-images}product/label-vegetarian.svg');
            }
        }
    }

    &__new-tag {
        display: none;
        cursor: default;
    }

    &__main {
    }

    &__title {
        display: none;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        margin: 0 0 $spacer;
        color: $color-brand-accent;

        &-mealtype {
            font-style: italic;
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            color: $color-brand-accent;
        }

        &--mobile {
            display: block;
            text-align: center;
        }
    }

    &__description {
        white-space: pre-line;

        &-new {
            color: $color-brand-2;
            font-weight: $font-weight-bold;
            font-style: italic;
        }
    }

    &__tabs {
        border-bottom: 2px solid $border-color-light;
        display: flex;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        column-gap: $spacer--medium;
        max-width: 100%;
        overflow-y: visible;
        overflow-x: auto;
    }

    &__tab {
        margin: 0;
        list-style-type: none;
        padding: $spacer--extra-small 0;

        a {
            color: $color-brand-accent;

            &:hover {
                color: $color-brand;
                text-decoration: none;
            }
        }

        &--active {
            border-bottom: 2px solid $color-brand;
            // margin-bottom: -2px;

            a {
                color: $color-brand;
            }
        }
    }

    &__sheet {
        padding: $spacer--small 0 0;
        display: none;

        &-title {
            display: none;
            font-weight: bold;
        }

        &-content {
        }

        &--visible {
            display: block;
        }
    }

    &__fact {
        padding: $spacer--small;
        background: $bg-color-brand-lighter;
        margin-top: $spacer;
        font-size: $font-size-base - 1;

        &-title {
            color: $color-brand;
            font-weight: bold;
            font-style: italic;
        }
    }

    &__heating {
        margin-bottom: $spacer--small;

        &-title {
            font-weight: bold;

            &-label {

            }
            &-summary {
                font-style: italic;
                color: $color-brand;
            }
        }

        &-preferred {
            color: $color-brand-2;
            font-style: italic;
        }
    }

    &__nutrition {
        &-table {
            width: 100%;

            th,
            td {
                text-align: left;
                padding: calc($spacer--extra-small / 2) $spacer--extra-small;
            }

            thead {
                th,
                td {
                    border-bottom: 2px solid $border-color-base;
                }
                th {
                    font-weight: bold;
                }
            }
            tbody {
                th,
                td {
                    border-bottom: 1px solid $border-color-light;
                }
                tr.-is-child {
                    th:first-child,
                    td:first-child {
                        padding-left: $spacer--medium;
                    }
                }
            }
        }
        @include mq($screen-sm) {
            &-table {
                width: 80%;
            }
        }
    }

    &__tags {
        display: flex;
        justify-content: flex-end;
        margin: -40px 0 $spacer--extra-small;
        padding: $spacer--extra-small 0;

        &--list {
            display: block;
            margin-top: $spacer--small;
        }
    }

    &__tag {
        $icon-width: 26px;
        flex: 0 0 20px;
        margin: 0 $spacer--extra-small 0 0;
        list-style-type: none;
        font-size: $font-size-small;
        color: $color-brand-accent;
        background: rgba($bg-color-brand-lightest, 0.8) no-repeat $spacer--extra-small center;
        background-size: ($icon-width - 4px) auto;
        padding: 0 $spacer--extra-small 0 $spacer--extra-small + $icon-width;
        line-height: 30px;
        border-radius: 5px;

        &--gf {
            background-image: url('#{$path-images}product/tag-gf.svg');
        }
        &--lf {
            background-image: url('#{$path-images}product/tag-lf.svg');
        }

        .product-detail__tags--list & {
            display: block;
            margin: 0;
            padding: 0 0 0 30px;
            color: inherit;
            background-color: transparent;
            background-size: 26px auto;
            background-position: left center;
            line-height: 30px;
            text-align: left;
        }
    }

    @include mq($screen-l) {
        &__product {
            display: flex;
            padding: 0 0 $spacer--medium 0;
            margin-top: $spacer--large;
        }

        &__media {
            flex-grow: 1;
            align-self: flex-start;

            &-label {
                top: $spacer--medium;
            }
            &-image {
                max-height: 450px;
            }
        }

        &__new-tag {
            display: block;
            position: absolute;
            bottom: $spacer--medium;
            right: 0;
            color: $white;
            font-weight: bold;
            font-size: $font-size-large;
            font-style: italic;
            padding: 2px 8px 2px 16px;
            background: url("data:image/svg+xml,%3Csvg reserveAspectRatio='none' version='1.1' viewBox='0 0 70 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7 0h63v30h-70c2-10 4-20 7-30z' fill='%23dd0000'/%3E%3C/svg%3E%0A") no-repeat left bottom;
            background-size: 100%;
        }

        &__main {
            flex: 0 0;
            flex-basis: $product-detail__main-width;
            box-sizing: content-box;
            padding: $spacer--medium $product-detail__padding-x 0;
        }

        &__title {
            display: block;
            font-size: $font-size-extra-large;

            &--mobile {
                display: none;
            }
        }

        &__description {
            &-new {
                display: none;
            }
        }

        &__tags {
            margin: $spacer--medium 0 0;
            padding: 0;
            background: none;
            justify-content: flex-start;
        }

        &__tag {
            margin: 0 0 0 $spacer--medium;
            padding: 35px 0 0;
            background-color: transparent;
            background-position: center top;
            background-size: auto 30px;
            text-align: center;
            line-height: $font-line-height;
        }
    }

    &-upsell {
        background: $bg-color-brand-lighter;
        padding: $spacer;
        margin: $spacer $spacer 0;

        &__image {
            border: 5px solid $white;
            width: 200px;

            &--mobile {
                margin: $spacer auto;
            }
            &--desktop {
                display: none;
            }
        }

        &__description {
            white-space: pre-line;
        }

        &--small {
            .product-detail-upsell {
                &__image--mobile {
                    flex: 80px 0 1;
                    width: 80px;
                    order: 1;
                    margin: 0;
                }

                &__image--desktop {
                    width: 125px;
                }

                &__title {
                    flex: 200px 1 0;
                    order: 2;
                    margin-left: $spacer;
                    font-size: $font-size-medium;
                }

                &__tabs {
                    order: 3;
                    flex-basis: 100%;
                    justify-content: flex-end;
                }

                &__mealtype {
                    display: none;
                }

                &__head {
                    flex-direction: row;
                    flex-wrap: wrap;
                    text-align: left;
                }

                @include mq($screen-l) {
                    &__title {
                        margin-left: 0;
                    }
                }
            }
        }

        &__details {
        }

        &__head {
            display: flex;
            flex-direction: column;
            padding-bottom: $spacer--extra-small;
            border-bottom: 2px solid $color-brand-light;
            text-align: center;
        }

        &__title {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
            color: $color-brand-accent;
            margin: 0;
        }

        &__mealtype {
            font-style: italic;
            font-size: $font-size-base;
            font-weight: $font-weight-base;

            &-new {
                font-weight: $font-weight-bold;
                color: $color-highlight;
            }
        }

        &__tabs {
            margin: 0 0 0 auto;
            list-style-type: none;
            display: flex;
            padding: 0;
        }

        &__tab {
            $tab-padding: $spacer--extra-small;
            display: flex;
            align-items: center;
            margin-left: $spacer--medium;
            padding-bottom: $tab-padding;
            margin-bottom: -1 * $tab-padding;

            &:first-child {
                margin-left: 0;
            }

            //&::after {
            //    width: 0.75em;
            //    height: 1.4em;
            //    display: inline-block;
            //    content: '';
            //    background: url('#{$path-images}icon-chevron-red.svg') no-repeat center center;
            //    background-size: contain;
            //    margin-left: 0.25em;
            //    transition: transform 300ms ease;
            //}

            a {
                color: $color-brand-accent;
                cursor: pointer;

                &:hover {
                    color: $color-brand;
                    text-decoration: none;
                }
            }

            &--active {
                $border-width: 2px;
                border-bottom: $border-width solid $color-brand;
                margin-bottom: -1 * ($tab-padding + $border-width);

                a {
                    color: $color-brand;
                }

                //&::after {
                //    transform: rotate(180deg);
                //}
            }
        }

        &__content {
            padding: $spacer--extra-small 0 0 0;
        }

        .product-add {
            background: transparent;
            padding-bottom: $spacer--extra-small;
            margin-top: 0;
        }

        &--popover {
            .product-add {
                padding: 0;
            }
        }

        @include mq($screen-l) {
            display: flex;
            align-items: flex-start;
            padding: $spacer 0 $spacer $spacer;

            &__image {
                flex-grow: 0;

                &--desktop {
                    display: block;
                }
                &--mobile {
                    display: none;
                }
            }

            &__details {
                flex-grow: 1;
                padding: 0 $product-detail__padding-x 0 $spacer--medium;
            }

            &__head {
                align-items: flex-end;
                flex-direction: row;
                text-align: left;
            }

            &__content {
            }

            .product-add {
                margin: 0 0 0 auto;
                width: $product-detail__main-width;
                padding-right: 0;
            }
        }
    }

    .popover--product-detail & {
        box-shadow: none;
        margin: 0;
    }
}
