.coupon-form {
    &--loading {
        opacity: 0.5;
    }

    &__summary {
        display: flex;
        width: 100%;

        &-description
        {
            flex: 1 1 auto;
            align-self: center;
            font-style: italic;
        }

        &-amount {
            flex: 0 0 auto;
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
            line-height: $font-line-height;
            margin-left: $spacer--small;
        }

        &-remove {
            flex: 0 0 auto;
            width: $font-size-large;
            height: $font-size-large * $font-line-height;
            background: url('#{$path-images}icon-delete.svg') no-repeat center center;
            background-size: contain;
            margin-left: $spacer--small;
        }
    }
}
