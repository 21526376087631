/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */

#mc_embed_signup {
    @include content-block();
    margin: 0 auto $spacer--semi-medium;
    font-family: $font-family-base;

    @include mq($screen-m) {
        margin: 0 auto $spacer--semi-medium;
    }

    h2 {
        margin-top: 0;
    }

    form {
        display: block;
        position: relative;
        text-align: left;
        padding: 0;
    }

    input[type="text"],
    input[type="email"],
    input[type="phone"] {
        @extend .input__field;
    }

    input[type=checkbox] {
        -webkit-appearance: checkbox;
    }

    input[type=radio] {
        -webkit-appearance: radio;
    }

    .small-meta {
        font-size: 11px;
    }

    .nowrap {
        white-space: nowrap;
    }

    .mc-field-group {
        clear: left;
        position: relative;
        width: 96%;
        min-height: 50px;
    }

    .size1of2 {
        clear: none;
        float: left;
        display: inline-block;
        width: 46%;
        margin-right: 4%;
    }

    .mc-field-group label {
        display: block;
        margin-bottom: 3px;
    }

    .mc-field-group input {
        display: block;
        width: 100%;
    }

    .mc-field-group select {
        display: inline-block;
        width: 99%;
        padding: 5px 0;
        margin-bottom: 2px;
    }

    .indicates-required {
        text-align: right;
        font-size: 11px;
        margin-right: 4%;
    }

    .asterisk {
        color: #e85c41;
        font-size: 85%;
        font-weight: normal;
        position: relative;
    }

    .clear {
        clear: both;
    }

    .mc-field-group.input-group ul {
        margin: 0;
        padding: 5px 0;
        list-style: none;
    }

    .mc-field-group.input-group ul li {
        display: block;
        padding: 3px 0;
        margin: 0;
    }

    .mc-field-group.input-group label {
        display: inline;
    }

    .mc-field-group.input-group input {
        display: inline;
        width: auto;
        border: none;
    }

    div#mce-responses {
        float: left;
        top: -1.4em;
        padding: 0em .5em 0em .5em;
        overflow: hidden;
        width: 90%;
        margin: 0 5%;
        clear: both;
    }

    div.response {
        margin: 1em 0;
        padding: 1em .5em .5em 0;
        font-weight: bold;
        float: left;
        top: -1.5em;
        z-index: 1;
        width: 80%;
    }

    #mce-error-response {
        display: none;
    }

    #mce-success-response {
        color: #529214;
        display: none;
    }

    label.error {
        display: block;
        float: none;
        width: auto;
        text-align: left;
        padding: .5em 0;
    }

    #mc-embedded-subscribe-form div.mce_inline_error {
        display: inline-block;
        margin: 2px 0 1em 0;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: normal;
        z-index: 1;
        color: #e85c41;
    }

    #mc-embedded-subscribe-form input.mce_inline_error {
        border: 2px solid #e85c41;
    }

    .buttons {
        margin-top: 2em;
    }
}
