.popover-edit-form {
    text-align: center;

    &__label {
        //text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.7);
        font-size: $font-size-base;
        color: $color-brand-accent;
        font-style: italic;
        font-weight: bold;
        margin: 0 0 $spacer;

        &-date {
            display: block;
            font-weight: $font-weight-base;
        }
        &-sub {
            display: block;
            font-size: $font-size-small;
            margin: -1 * $spacer--extra-small 0 $spacer--extra-small;
        }
    }

    &__slider {
        margin-left: auto;
        margin-right: auto;
    }

    &__fieldset {
        padding-bottom: $spacer--medium;
        border-bottom: $border-inverse;
        margin-bottom: $spacer--medium;
    }

    &__field-message {
        font-style: italic;
        margin: $spacer--extra-small 0 0;
    }

    &__header {
        text-align: center;
        color: $color-brand-accent;

        &-title {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            color: $color-brand-accent;
            margin-bottom: $spacer--small;
            font-style: italic;
        }

        &-subtitle {
            display: none;
            font-style: italic;
            font-size: $font-size-small;
            margin-bottom: $spacer--extra-small;
        }

        @include mq($screen-m) {
            &-title {
                font-size: $font-size-medium;
            }

            &-subtitle {
                display: block;
                font-size: inherit;
            }
        }
        @include mq-height($screen-height-l) {
            &-subtitle {
                display: block;
            }
        }
    }

    &__postcode-message {
        background: $popover__background;
        padding: $spacer--small;
        margin-bottom: $spacer--small;
        position: relative;
        text-align: center;

        p {
            display: none;
        }

        &-header {
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
            color: $color-brand-accent;
            margin-bottom: $spacer--extra-small;
            font-style: italic;
        }

        &::after {
            content: '';
            background: url('#{$path-images}arrow-flow-down.svg') no-repeat center center;
            background-size: contain;
            width: 100%;
            height: $spacer--medium;
            position: absolute;
            bottom: calc(-1 * $spacer--medium / 2);
            left: 0;
        }

        @include mq($screen-m) {
            &-header {
                font-size: $font-size-medium;
                font-weight: $font-weight-bold;
            }

            p {
                display: block;
            }
        }
    }

    @include mq($screen-m) {
        &__label {
            &-date {
                display: inline;
            }
        }
    }
}

.popover--cart-add {
    @include mq($screen-m) {
        min-width: 450px;
    }
}
