.order-summary {
    &__order {
        background: $bg-color-brand-lighter;
        margin: 0 -1 * $spacer $spacer--extra-small;
        padding: $spacer;
    }

    &__date {
        color: $color-brand-accent;
        font-size: $font-size-large;
        font-weight: normal;
        margin: 0 0 3px;
        line-height: 1.2;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__amount {
        float: right;
        font-size: $font-size-large;
    }

    &__meta {
        font-style: italic;
        margin: 2px 0;

        &-time {

        }
        &-address {

        }
    }

    &__items {
        display: block;
        margin: 2px 0;
        padding: 0;
    }

    &__labels {
        float: right;
        display: flex;
        margin: 0;
        gap: 8px;
    }

    &__label {
        display: block;
        font-size: $font-size-small;
        border: 1px solid $color-brand;
        color: $color-primary;
        border-radius: 4px;
        margin: 1px 0;
        padding: 1px $spacer--extra-small;
        cursor: default;
    }

    &__details {
        transition: all;
    }

    &__actions {
        margin-bottom: $spacer--medium;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        min-height: 45px;

        &-wrapper {
            overflow: hidden;
        }
    }

    &__action {
        margin-left: $spacer--small;
        margin-bottom: $spacer;

        &--detail {
            color: $color-secondary;
        }

        &--pull {
            margin-left: 0;
            margin-right: auto;
        }

        &--back {
            color: $color-brand-accent;
            padding-left: 30px;
            background: url('#{$path-images}/circle-prev-bg.svg') no-repeat left center;
            background-size: 24px 24px;
        }

        &--cancel {
            color: $color-cancel;
        }
    }
}

.order-item-summary {
    display: flex;
    margin: 0 -1 * $spacer $spacer--extra-small;
    padding: $spacer;
    background: $bg-color-brand-lightest;

    &__image {
        flex: 0 0 80px;
        border: 3px solid $white;
        margin-right: $spacer;

        img {
            max-height: 60px;
            width: 80px;
            object-fit: cover;
        }
    }

    &__contents {
        flex: 1 1 auto;
    }

    &__name {
        font-size: large;

        a {
            color: $color-brand-accent;
        }
    }

    &__description {
        font-style: italic;
    }

    &__amount {
        flex: 0 1 auto;
        font-size: $font-size-large;
    }
}

.order-item-slide-enter-active {
    transition: all 0.3s ease;
}

.order-item-slide-enter-to, .order-item-slide-leave-from {
    opacity: 1;
}

.order-item-slide-enter-from {
    transform: translateY(-10px);
    opacity: 0;
}

.actions-slide-enter-active,
.actions-slide-reverse-enter-active,
.actions-slide-leave-active,
.actions-slide-reverse-leave-active,
{
    transition: transform .3s ease, opacity .3s ease;
}

.actions-slide-enter-from,
.actions-slide-reverse-leave-to {
    transform: translateX(80px);
    opacity: 0;
}
.actions-slide-leave-to,
.actions-slide-reverse-enter-from {
    transform: translateX(80px);
    opacity: 0;
}
