.checkout-success__holder {
    max-width: 850px;
    margin: 0 auto;

    @include mq($screen-m) {
        margin-top: $spacer--large;
    }
}
.checkout-success {
    @include content-block;

    &__title, h1 {
        font-size: $font-size-super-extra-large;
        color: $color-brand-accent;
        margin: 0 0 $spacer;
    }

    &__text {
        p {
            font-size: $font-size-large;
        }
    }

    &__illu {
        max-width: 120px;
        max-height: 130px;
        float: right;
        transform: rotate(8deg);
    }

    &__orders {
        border-top: 2px solid $border-color-base;
        margin: $spacer--extra-large 0 0;
        padding: $spacer--large 0 0;

        &-title {
            font-size: $font-size-extra-large;
            color: $color-primary;
            margin: 0 0 $spacer--medium;
        }
    }
}
