.cart-summary {
    padding: $spacer--semi-medium $spacer--large;

    &__title {
        margin: 0 0 $spacer--small;
        font-size: $font-size-extra-large;
        font-weight: $font-weight-bold;
        font-style: italic;
    }

    &__label {

    }

    &__price {
        text-align: right;
        white-space: nowrap;
    }

    &__days {
        padding-bottom: $spacer--small;
        margin-bottom: $spacer--small;
        border-bottom: 1px solid $border-color-base;

        &-table {
            width: 100%;
            border-spacing: 0;

            td {
                padding: 2px 0;
            }
        }
    }

    &__shipping {
        .cart-summary__label {
            padding-left: 1em;
            font-style: italic;
        }
    }

    &__totals {
        &-table {
            width: 100%;
            border-spacing: 0;

            td {
                padding: 2px 0;
            }
        }
    }

    &__total {
        &--grand_total {
            td {
                font-weight: $font-weight-bold;
                font-size: $font-size-medium;
            }
        }
        &--tax {
            td {
                font-size: $font-size-small;
            }
        }
    }
}
