.giftcard-buy {
    background: $bg-color-brand;
    padding: $spacer--medium;

    &__amount {
        &-before,
        &-after {
            display: inline-block;
        }

        &-input {
            display: inline-block;
            width: 6em;
            margin: 0 $spacer;
        }
    }

    &__action {
        text-align: left;
        border-top: $border-inverse;
        padding-top: $spacer--small;
    }
}
