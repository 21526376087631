.deposit-summary {
    background: $bg-color-brand-lightest;
    margin: 0 -1 * $spacer $spacer--small;

    &__header {
        display: flex;
        align-items: center;
        column-gap: $spacer;
        padding: $spacer;
        flex-wrap: wrap;
        cursor: pointer;

        /* To remove the styling of a button */
        background: $bg-color-brand-lighter;
        width: 100%;
        border: none;
        text-align: left;
        margin: 0;
        appearance: auto;
    }

    &__header-date {
        color: $color-brand-accent;
        font-size: $font-size-large;
        font-weight: normal;
        margin: 0 0 3px;
        line-height: 1.2;
        order: 1;
        flex-grow: 1;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__header-summary {
        opacity: 0.5;
        flex-basis: 100%;
        order: 3;
        font-size: $font-size-small;
    }

    &__header-chevron {
        background: url('#{$path-images}/circle-next.svg') no-repeat 50%;
        background-size: contain;
        width: 1.8em;
        height: 1.8em;
        transition: transform .2s;
        transform: rotate(90deg);
        order: 2;
    }

    @include mq($screen-m) {
        &__header-summary {
            flex-basis: auto;
            order: 2;
        }

        &__header-chevron {
            order: 3;
        }
    }

    &__body {
        padding: $spacer;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        column-gap: $spacer;
        row-gap: $spacer--extra-small;
        flex-direction: column;
        margin-bottom: $spacer;
    }

    &__item-label {
        font-weight: 600;
    }

    &__item-bowls {
        font-size: $font-size-small;
    }

    @include mq($screen-sm) {
        &__item-bowls {
            font-size: $font-size-base;
        }
    }

    @include mq($screen-m) {
        &__header-summary {
            font-size: $font-size-base;
        }

        &__item {
            flex-direction: row;
        }

        &__item-label {
            width: 13em;
        }
    }

    &:not(.-is-open) {
        .deposit-summary {
            &__body {
                display: none;
            }

            &__header-chevron {
                transform: rotate(270deg);
            }
        }
    }
}
