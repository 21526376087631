/**
 * Ported from original LESS code
 */

//
//  Variables
//  ---------------------------------------------

$lac-notification-background-color: $color-brand;
$lac-notification-color: $white;
$lac-notification-links-color: $white;

//
//  Common
//  ---------------------------------------------

.lac-notification-sticky {
    position: static; // Don't want it to be sticky
    z-index: 999;
}

.lac-notification {
    background-color: $lac-notification-background-color;
    color: $lac-notification-color;

    .top-container {
        display: flex;
        align-items: center;
        font-family: $font-family-sans-serif;
        padding: $spacer--extra-small $spacer;
    }

    .lac-notification-icon {
        flex: 0 0 auto;
        order: 1;
        margin-right: $spacer;
        padding: 2px 6px;
        background: rgba(255, 255, 255, 0.5);

        .logo-img {
            display: block;
            height: $spacer--semi-medium;
            width: auto;
        }
    }

    .lac-notification-text {
        flex: 1 1 auto;
        order: 2;
    }

    .lac-notification-links {
        flex: 0 0 auto;
        order: 3;

        a {
            color: $lac-notification-links-color;
        }

        .lac-notification-close-link {
            &:after {
                background: url('../Magento_LoginAsCustomerFrontendUi/images/close.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                content: ' ';
                display: inline-block;
                margin-left: $spacer;
                vertical-align: middle;
                width: $spacer--small;
            }
        }
    }
}

@include mq($max-screen: $screen-m) {
    .lac-notification {
        .lac-notification-icon {
            display: none;
        }

        .lac-notification-links {
            .lac-notification-close-link {
                &:after {
                    display: none;
                }
            }
        }
    }
}
