@mixin focus($mode: '') {
    outline-offset: $focus-outline-offset;
    @if $mode == 'dark' {
        outline: $border-focus-dark;
    }
    @else {
        outline: $border-focus;
    }
}

@mixin focus-inline($mode: '') {
    outline: $outline-focus-inline;
    text-decoration: $text-decoration-focus-inline;
    @if $mode == 'dark' {
        color: $color-focus-inline-dark;
        background-color: $bg-focus-inline-dark;
    }
    @else {
        color: $color-focus-inline;
        background-color: $bg-focus-inline;
    }
}

@mixin focus-input() {
    outline: $form-elements-border-focus;
    outline-offset: -1px;
    box-shadow: $form-input-box-shadow-focus;
// Removed the transition. It was annoying.
    transition: none;
}
