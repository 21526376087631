@import 'cms-page-variables';

.page-layout-1column {
    .cms-page {
        &__content {
            max-width: $cms-page__max-width;
            margin: $cms-page__margin;

            @include mq($screen-m) {
                max-width: $cms-page__max-width\@medium;
            }

            @include mq($screen-l) {
                max-width: $cms-page__max-width\@large;
            }

            @include mq($screen-xl) {
                max-width: $cms-page__max-width\@xlarge;
            }

            .cms-contact &,
            .cms-contact-us & {
                max-width: 100%;
            }
        }
    }
}

.cms-page {
    &__content {
        p {
            margin: 0.4em 0 1em 0;
        }

        h2 {
            margin-top: 0.8em;
        }

        > h2 {
            margin-top: 0;
        }

        h2, h3, h4 {
            color: $color-brand-accent;
        }
    }
}
