.customer-account-create {
    .header-title {
        background-image: url('#{$path-images}illu-pan-white.svg');
    }

    .account-create {
        @extend .content-form;
    }

    @include mq($screen-m) {
        .header-title {
            background-image: none;
        }
    }
}
