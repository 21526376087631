.form-page {
    @include mq($max-screen: $screen-l) {
        background: $bg-color-brand;

        .content-form,
        &.customer-account-create .account-create {
            background: $bg-color-brand-lighter;
        }
        .sidebar-highlight {
            background: $bg-color-brand-lightest;
            border: none;
        }
    }
}
