$image__width                   : 100% !default;
$image__transition              : $transition-fade !default;
$image__wrapper-background-color: $white !default;

.image {
    display: block;
    max-width: $image__width;
    transition: $image__transition;

    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

img,
picture {
    @extend .image;
}

.lazyload-wrapper {
    position: relative;
    background-color: $image__wrapper-background-color;
    transition: $transition-base;
}
