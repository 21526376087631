$input__control-field-width                     : $spacer--medium !default;

.input--control,
.control-list__item {
    .input__field {
        width: $input__control-field-width;
        height: inherit;
        align-self: flex-start;
        margin: 4px $spacer--extra-small 0 0;
        float: left;
    }

    .input__label,
    .control__label {
        margin-left: $input__control-field-width;
    }
}
