.v-enter-active,
.v-leave-active {
    transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.none-enter-active,
.none-leave-active {
}

.none-enter-from,
.none-leave-to {
}

.fade-slide-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}
.fade-slide-enter-from {
    transform: translateY(10px);
    opacity: 0;
}
.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: all 0.4s ease;
}

.fade-slide-enter-to,
.fade-slide-leave-from {
    opacity: 1;
}
