$side-menu__trigger-wrapper-border      : 1px solid $gray-light !default;
$side-menu__bq-visibility-max: $screen-l !default;

$side-menu__trigger-size                : 28px !default;
$side-menu__trigger-margin              : 0 !default;
$side-menu__trigger-padding             : 0 $spacer !default;
$side-menu__trigger-padding\@medium     : 0 $spacer !default;
$side-menu__trigger-border              : none !default;
$side-menu__trigger-background-color    : transparent !default;

$side-menu__trigger-text-font-size      : 10px !default;
$side-menu__trigger-text-line-height    : 10px !default;
$side-menu__trigger-text-alignment      : center !default;
$side-menu__trigger-text-color          : $gray !default;
$side-menu__trigger-icon-size           : 22px !default;
$side-menu__trigger-icon-size\@medium   : 32px !default;
$side-menu__trigger-icon-margin         : 0 !default;
$side-menu__trigger-icon-margin\@medium : 0 !default;
$side-menu__trigger-icon-padding        : 0 !default;
$side-menu__trigger-icon-color          : $gray-darker !default;
$side-menu__trigger-icon-color--hover   : $color-primary !default;

$side-menu__content-top                 : 0 !default;
$side-menu__content-bottom              : 0 !default;
$side-menu__content-right               : calc(100% + 2px) !default;
$side-menu__content-height              : 100% !default;
$side-menu__content-max-width           : 280px !default;
$side-menu__content-max-width\@medium   : 400px !default;
$side-menu__content-max-height          : 100vh !default;
$side-menu__content-transform           : translateX(0) !default;
$side-menu__content-transform--open     : translateX($side-menu__content-right) !default;
$side-menu__content-padding             : 0 !default;
$side-menu__content-background-color    : $gray-light !default;
$side-menu__content-transition          : $transition-base !default;
$side-menu__content-box-shadow          : 0 2px 4px 0 rgba(0, 0, 0, 0.5) !default;

$side-menu__dropdown-width              : 100% !default;
$side-menu__dropdown-margin             : 0 !default;

$side-menu__overlay-background-color    : rgba(0, 0, 0, 0.6) !default;

.side-menu {
    @include mq($side-menu__bq-visibility-max) {
        display: none;
    }

    &--is-open {
        .side-menu__content {
            opacity: 1;
            transform: $side-menu__content-transform--open;
            visibility: visible;
        }

        .side-menu__overlay {
            width: 100%;
            opacity: 1;
        }
    }

    &__trigger-wrapper {
        display: flex;
        justify-content: flex-end;
        border-bottom: $side-menu__trigger-wrapper-border;
    }

    &__trigger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 0;
        margin: $side-menu__trigger-margin;
        padding: $side-menu__trigger-padding;
        border: $side-menu__trigger-border;
        background-color: $side-menu__trigger-background-color;
        cursor: pointer;

        @include mq($screen-m) {
            border: 0;
            padding: $side-menu__trigger-padding\@medium;
        }

        &:focus,
        &:hover {
            .side-menu__trigger-icon {
                fill: $side-menu__trigger-icon-color--hover;
            }
        }

        &--close {
            position: absolute;
            right: $spacer;
            height: 58px;
        }
    }

    &__trigger-text {
        font-size: $side-menu__trigger-text-font-size;
        line-height: $side-menu__trigger-text-line-height;
        text-align: $side-menu__trigger-text-alignment;
        color: $side-menu__trigger-text-color;
    }

    &__trigger-icon {
        width: $side-menu__trigger-icon-size;
        height: $side-menu__trigger-icon-size;
        background: url('../images/header-hamburger.svg') no-repeat center center;
        background-size: contain;
        display: block;

        @include mq($screen-m) {
            margin: $side-menu__trigger-icon-margin\@medium;
            width: $side-menu__trigger-icon-size\@medium;
            height: $side-menu__trigger-icon-size\@medium;
        }

        &--close {
            background: url('../images/circle-prev.svg') no-repeat center center;
            background-size: contain;
        }
    }

    &__content {
        position: fixed;
        top: 0;
        bottom: 0;
        right: $side-menu__content-right;
        width: 100%;
        height: 100%;
        max-width: $side-menu__content-max-width;
        max-height: $side-menu__content-max-height;
        padding: $side-menu__content-padding;
        z-index: 100;
        overflow-y: auto;
        background: $side-menu__content-background-color;
        box-shadow: $side-menu__content-box-shadow;
        opacity: 1;
        transform: $side-menu__content-transform;
        transition: $side-menu__content-transition;
        visibility: hidden;
        @include mq($screen-m) {
            max-width: $side-menu__content-max-width\@medium;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                padding: $spacer $spacer--medium;

                &.separator {
                    padding: 0;
                    margin: $spacer 0;
                    border-bottom: 1px solid $border-color-base;
                }

                a {
                    color: $color-brand-accent;
                }
            }
        }
    }

    &__content-header {
        height: 58px;
        background-color: rgba(255, 255, 255, 0.45);
        padding: 0 $spacer--medium;
        line-height: 55px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        a {
            color: $color-brand-accent;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        background-color: $side-menu__overlay-background-color;
        @include mq($screen-xl) {
            display: none;
        }
    }

    &__dropdown {
        margin: $side-menu__dropdown-margin;
        width: $side-menu__dropdown-width;
    }
}
