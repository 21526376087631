.blog-sidebar {
    display: none;

    @include mq($screen-l) {
        display: block;
    }

    &.sidebar {
        padding: 0 0 0 15px;
        flex-basis: calc(100% / 3);
        max-width: none;
    }

    &__categories {
        @extend .content-block-small;
        margin-bottom: 30px;
    }

    .block-title {
        font-size: $font-size-extra-large;
        font-style: italic;
        margin-bottom: 15px;
    }

    .categories-list {
        list-style: none;
        padding: 0;

        &__item {
            margin: 0 0 10px 0;
            padding: 0 0 0 15px;

            &:before {
                content: '›';
                display: block;
                position: absolute;
                margin-left: -15px;
                color: $color-brand;
            }

            &-info {
                font-size: $font-size-small;
                color: $color-secondary;
            }
        }
    }
}
