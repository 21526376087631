@mixin content-block(
    $padding--large: $spacer--extra-large $spacer--super-extra-large,
    $padding--base: $spacer--semi-medium
) {
    background: $bg-color-base;
    padding: $padding--base;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // Place items full width on mobile
    margin: 0 $grid__gutter-compensation $spacer--semi-medium;

    @include mq($screen-m) {
        padding: $padding--large;
        margin: 0 0 $spacer--semi-medium;
    }
}

@mixin content-block-small() {
    @include content-block($padding--large: $spacer--large);
}
