$nav-days__indicator-height: 8px;
$nav-days__day-border-width: 2px;
$nav-days__day-border-color: $gray-light;
$nav-days__day-dom-font-size: 35px;
$nav-days__day-dow-font-size: 14px;
$nav-days__day-alt-font-size: 14px;

.nav-days {
    display: flex;

    &__wrapper {
        position: sticky;
        top: 60px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.11);
        background: $bg-color-base;
        z-index: $z-index-normal;

        @include mq($screen-xl) {
            top: -12px;
        }
    }

    &__list {
        display: flex;
        flex-basis: auto;
        flex-grow: 1;
        padding: 0;
        margin: 0;
        list-style-type: none;
        overflow: auto;
        @include hide-scrollbar();

        @include mq($screen-xl) {
            padding-top: $spacer--small;
        }
    }

    &__cart {
        flex-basis: 88px;
        flex-grow: 0;
        justify-self: flex-end;
        padding: $spacer--large $spacer 0 0;
        display: none;

        .header-cart {
            float: right;
        }

        @include mq($screen-xl) {
            display: block;
        }
    }

    &__day {
        flex: 1;
        text-align: center;
        position: relative;
        padding: $spacer--small $spacer $spacer--extra-small;
        white-space: nowrap;

        // The --link:hover state needs to override this color, hence it needs to be higher in the source
        &--faded {
            .nav-days__day-wrapper {
                color: $color-inactive;
            }
        }

        &-wrapper {
            display: block;
            color: $color-brand-accent;
            line-height: 1.0em;

            &--link {
                &:hover {
                    text-decoration: none;
                    color: $color-brand;
                }
            }
        }

        &-dom {
            display: inline;
            font-size: $font-size-base;
        }

        &-dow {
            display: inline;
            font-size: $font-size-base;
        }

        &-alt {
            font-style: italic;
            display: none;
        }

        &-indicators {
            display: block;
            height: $nav-days__indicator-height;
            line-height: $nav-days__indicator-height;
            margin: calc($spacer--extra-small / 2) 0;
        }

        &-indicator {
            display: inline-block;
            height: $nav-days__indicator-height;
            width: $nav-days__indicator-height;
            background: $color-brand;
            border-radius: calc($nav-days__indicator-height / 2);
            line-height: $nav-days__indicator-height;

            &--cart {
                background: $color-brand-2;
            }

            &--order {
                background: $border-color-base;
            }
        }

        &--active {
            &::after {
                display: block;
                content: '';
                z-index: $z-index-high;
                position: absolute;
                left: -1 * $nav-days__day-border-width;
                bottom: 0;
                width: calc(100% + #{2 * $nav-days__day-border-width});
                border-bottom: 3px solid $color-brand;
            }
        }

        &--past,
        &--weekend {
            display: none;
        }

        &--fuzzy {
            .nav-days__day-dom {
                display: none;
            }
        }
    }


    @include mq($screen-m) {
        &__list {
            overflow: visible;
        }

        &__day {
            border-left: $nav-days__day-border-width solid $nav-days__day-border-color;
            padding: 4px 0 6px;
            cursor: default;
            white-space: normal;

            &:first-child {
                border-left: none;
            }

            @include mq($screen-xl) {
                &:last-child {
                    border-right: $nav-days__day-border-width solid $nav-days__day-border-color;
                }
                &:first-child {
                    border-left: $nav-days__day-border-width solid $nav-days__day-border-color;
                }
            }

            // The --link:hover state needs to override this color, hence it needs to be higher in the source
            &--faded {
                .nav-days__day-wrapper {
                    color: $color-inactive;
                }
            }

            &-wrapper {
                line-height: $font-line-height;

                &--link {
                    &:hover {
                        text-decoration: none;
                        color: $color-brand;
                    }
                }
            }

            &-dom {
                display: block;
                font-size: $nav-days__day-dom-font-size;
                line-height: 40px;
            }

            &-dow {
                display: block;
                font-size: $nav-days__day-dow-font-size - 1;
                line-height: 16px;

                @include mq($screen-l) {
                    font-size: $nav-days__day-dow-font-size;
                    display: block;
                }
            }

            &-alt {
                font-style: italic;
                display: block;
                font-size: $nav-days__day-alt-font-size;
                line-height: 40px;
            }

            &-indicators {
                margin: $spacer--extra-small 0 calc($spacer--extra-small / 2);
            }

            &--active {
                &::after {
                    border-width: 5px;
                }
            }

            &--past,
            &--weekend {
                display: block;
            }

            &--fuzzy {
                .nav-days__day-dom {
                    display: inline;
                }
            }
        }
    }
}
