.checkout-cart-index {
    background: $bg-color-brand;

    .heading--page {
        font-size: $font-size-super-extra-large;
        font-style: italic;
        color: $color-brand-accent;
        line-height: 2em;
        background: url('#{$path-images}shoppingbag-white.svg') no-repeat center center;
        background-size: contain;
        margin: $spacer--semi-medium 0;
        text-align: center;
    }

    @include mq($screen-l) {
        .heading--page {
            font-size: $font-size-super-duper-extra-large;
        }
    }
}

.cart {
    &__sidebar {
        order: 1;
        background: $bg-color-base;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
        margin-bottom: $spacer--medium;
    }

    &__empty {
        text-align: center;

        p {
            margin-bottom: 1em;
        }
    }

    @include mq($screen-m) {
        &__sidebar {
            position: sticky;
            top: $spacer--medium;
        }
    }
}
