@import "checkbox";

.checkbox {
    &--fixed-checked {
        display: block;

        .checkbox__label {
            color: $checkbox__label-color--active;
            cursor: default;
        }

        .checkbox__icon {
            opacity: 1;
            fill: $checkbox__label-color--active;
            cursor: default;
        }
    }
}
