// TODO: check for missing custom styles and remove unused styles if needed

// Variables
@import '../Snowdog_Components/components/Atoms/variables/variables';

// Components
@import '../Snowdog_Components/components/styles/non-critical';

// Theme partials
//@import '../Amasty_Storelocator/styles/module';
//@import '../Amasty_GiftCard/styles/module';
@import '../Magento_Ui/styles/module';
//@import '../Magento_Catalog/styles/module';
@import '../Magento_Customer/styles/module';
//@import '../VladimirPopov_WebForms/styles/module';
//@import '../Magento_PageBuilder/styles/module';
//@import '../Magento_QuickOrder/styles/module';
//@import '../Yotpo_Yotpo/styles/module';
//@import '../Magento_Msrp/styles/module';
//@import '../PayPal_Braintree/styles/module';
@import '../Magento_ReCaptchaFrontendUi/styles/module';
@import '../Magento_Theme/styles/module-non-critical';

@import '../Mageplaza_Blog/styles/module';
@import '../Marleen_Friend/styles/account';
@import '../Ecomni_OrderDates/styles/address-warning';
@import '../Marleen_MailChimp/styles/module';

// Show sections hidden in critical.scss
.page-main,
.footer {
    display: block !important;
}
