@import "pager";

.pager {
    &__link {
        padding: 0 $spacer--extra-small;
    }

    &__item {
        &--current {
            &:after {
                z-index: 1;
            }
        }
    }
}
