$forgot-password__max-width: none;
$forgot-password__actions-padding: 0;
$forgot-password__info-margin-bottom: $spacer--semi-medium;
$forgot-password__info-color: $color-primary;
$forgot-password__info-font-size: $font-size-base;

@import "forgot-password";

.forgot-password {
    &__info {
        @include mq($screen-m) {
            text-align: inherit;
        }
    }

    &__actions {
        flex-direction: row-reverse;
    }
}
