.time-select {

    &__slider {
        display: block;

        &-selected {
            font-weight: $font-weight-bold;
            color: $color-brand-accent;
            font-size: $font-size-small;
            margin-top: $spacer;
        }
    }

    &__manual {
        display: none;

        // Need some more context to be more specific than the original rule
        @at-root .select--native &-select {
            width: auto;
        }
    }

    @include mq($screen-m) {
        &__slider {
            display: none;
        }

        &__manual {
            display: block;
        }
    }
}
