@import "../../Molecules/message/message-variables";

.cart-group {
    margin-bottom: $spacer--semi-large;

    &__title {
        font-size: $font-size-extra-large;
        font-style: italic;
        font-weight: $font-weight-bold;
        color: $color-brand-accent;
        margin: 0 0 $spacer--medium;
        text-align: center;
    }

    &__subtitle {
        font-size: $font-size-base;
        font-weight: normal;
        font-style: italic;

        a, .link {
            display: inline;
        }
    }

    &__message {
        padding: $spacer;
        margin-bottom: 2px;
        line-height: 1.3em;

        &--error {
            color: $message__color--error;
            background-color: $message__background--error;
        }
        &--warning {
            color: $message__color--warning;
            background-color: $message__background--warning;
        }
    }

    &__deposit {
        display: flex;
        justify-content: space-between;
        padding: $spacer $spacer;
        background: $bg-color-brand-lightest;
        margin-bottom: 2px;

        &-label {
            margin: 0;
            font-size: $font-size-medium;
        }
        &-amount {
            font-size: $font-size-medium;
        }
    }

    &__info {
        display: block;

        &-item {
            background: $bg-color-brand-lighter;
            padding: $spacer $spacer;
            margin-bottom: 2px;
        }

        &-label {
            font-weight: $font-weight-bold;
            display: inline-block;
            margin: 0;
            color: $color-brand-accent;
            font-style: italic;
        }

        &-change {
            border-bottom: 1px dashed $color-brand;
            text-decoration: none;
            line-height: 1.3em;
            color: darken($color-brand, 10%);

            &:hover {
                border-bottom-style: solid;
                text-decoration: none;
            }
        }
    }

    @include mq($screen-m) {
        &__message {
            padding: $spacer $spacer--small;
        }

        &__info {
            margin-bottom: 2px;
            display: flex;

            &-item {
                flex: 1 50%;
                margin-right: 2px;
                margin-bottom: 0;
                padding: $spacer $spacer--small;

                &:last-child {
                    margin-right: 0;
                }
            }

            &-label {
                display: block;
            }
        }

        &__deposit {
            padding: $spacer $spacer--small;
        }
    }
}
