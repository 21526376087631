$menu-day__bookmark-text-color                  : $color-inverse;

.slide-down-enter-active,
.slide-down-leave-active {
    transition: all 0.5s ease;
}
.slide-down-enter-from,
.slide-down-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

.menu-day {
    position: relative;
    min-height: 125px;
    margin: $spacer--small 0 $spacer--large;

    @include mq($screen-m) {
        margin: $spacer--extra-large 0 $spacer--super-extra-large;
    }

    &__collapsed {
        margin: $spacer--small 0 $spacer--large;

        a, .link {
            color: $color-inactive;
        }

        @include mq($screen-m) {
            margin: $spacer--semi-large 0 $spacer--extra-large;
        }
    }

    &__head {
        margin-bottom: $spacer;
        color: $color-brand-accent;
        font-weight: $font-weight-bold;
        font-size: $font-size-medium;

        &-line {
            display: flex;
            align-items: center;
        }

        @include mq($screen-m) {
            margin-bottom: $spacer--semi-medium;
        }
    }

    &__title {
        flex: 1;
        font-style: italic;

        &-prefix {
            display: none;
        }

        &-suffix {
            display: none;
        }

        &:first-child {
            &::first-letter {
                text-transform: capitalize;
            }
        }

        &-date {
            display: inline-block;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        @include mq($screen-m) {
            font-size: $font-size-super-extra-large;

            &-date {
                color: $color-brand;
                display: inline;

                &:not(:first-child)::first-letter {
                    text-transform: none;
                }
            }

            &-prefix {
                display: inline-block;
            }
            &-suffix {
                display: inline-block;
            }
        }
    }

    &__subtitle {
        font-size: $font-size-base;
        font-weight: normal;
        font-style: italic;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }
    }

    &__time {
        //background: url('#{$path-images}bike.svg') no-repeat left center;
        font-size: $font-size-base;
        display: flex;
        align-items: center;
        column-gap: $spacer--extra-small;

        &-value {
            text-decoration: none;
            font-weight: normal;

            @at-root #{selector-append(a, &)} {
                border-bottom: 1px dashed $color-brand;

                &:hover {
                    border-bottom-style: solid;
                    text-decoration: none;
                }
            }
        }

        &-icon {
            height: 1.3em;
            width: auto;
            fill: currentColor;
        }

        &-label {
            display: none;
        }

        @include mq($screen-m) {
            // Same as title
            // Half of the difference between the total height of the title minus the total height of the time, to center vertically
            //padding-top: ($font-size-super-extra-large * $font-line-height - $font-size-large * $font-line-height) / 2;
            //background-size: 78px auto;
            font-size: $font-size-large;

            &-label {
                display: initial;
            }

            &-value {
                font-weight: inherit;
            }
        }
    }

    &__bookmark {
        display: none;
        background: url('../images/date-label.svg') no-repeat 0 0;
        background-size: contain;
        color: $menu-day__bookmark-text-color;
        text-align: center;
        padding-top: 13px;
        width: 109px;
        height: 89px;
        position: absolute;
        left: -147px;
        top: -10px;

        @include mq($screen-xxl) {
            display: block;
        }

        &-dow {
            font-size: 18px;
            line-height: 1em;
        }

        &-dom {
            font-size: 40px;
            line-height: 1em;
        }
    }

    &__closed-message {
        font-size: $font-size-medium;
        line-height: 1.7;
        text-align: center;
        margin: $spacer--extra-large 0;

        @include mq($screen-m) {
            font-size: $font-size-large;
        }
    }
}
