$fieldset__border-top        : 1px solid $border-color-base !default;
$fieldset__margin            : $spacer--semi-large 0 $spacer !default;
$fieldset__padding           : $spacer--medium 0 0 !default;
$fieldset__legend-margin     : 0 0 $spacer--small !default;
$fieldset__legend-padding    : 0 !default;
$fieldset__legend-font-size  : $font-size-extra-large !default;
$fieldset__legend-font-weight: $font-weight-base !default;
$fieldset__legend-color      : $color-brand-accent !default;
$fieldset__legend-line-height: $font-line-height !default;

.fieldset {
    border: none;
    border-top: $fieldset__border-top;
    padding: $fieldset__padding;
    margin: $fieldset__margin;
    // Decrease top padding and margin by 10px for mobile
    padding-top: get-side($fieldset__padding, top) - $spacer--extra-small;
    margin-top: get-side($fieldset__margin, top) - $spacer;

    &:first-child {
        border-top: none;
        padding-top: 0;
        margin-top: 0;

        .fieldset__legend {
            margin-top: 0;
        }
    }

    &__legend {
        padding: $fieldset__legend-padding;
        margin: $fieldset__legend-margin;
        font-size: $fieldset__legend-font-size;
        line-height: $fieldset__legend-line-height;
        color: $fieldset__legend-color;

        &--hidden {
            @include visually-hidden();
        }
    }

    &__actions {
        margin-top: $spacer--large;

        &--push {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-end;

            > * {
                margin-left: $spacer--small;
            }
        }

        &--inline {
            margin-top: $spacer;
        }
    }

    &__sublegend {
        margin: $spacer 0 $spacer--small;
    }

    &--popover {
        padding: 0;
        margin: 0;
        border: 0;

        &__actions {
            margin-top: 0;
        }

        .fieldset__legend {
            font-weight: $font-weight-bold;
            font-size: $font-size-large;
            color: $color-brand-accent;
        }

        .input__label {
            text-align: left;
        }
    }

    @include mq($screen-m) {
        padding-top: get-side($fieldset__padding, top);
        margin-top: get-side($fieldset__margin, top);

        &--popover {
            padding-top: 0;
            margin-top: 0;
        }
    }
}
